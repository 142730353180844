import {
  useVGrid,
  VGrid,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE
} from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import {
  investorOrderMarketData,
  buildInvestorOrderMonitorColumnDefs
} from './investor-order-monitor.columns';
import { OrderMonitorVisibilityEventHander } from './grid-services/order-monitor.visibility.event-handler';
import { useInvestorOrderMonitorToolbarActions } from './toolbar-actions';
import { investorOrderGenerateOrderSummaryAction } from './grid-actions/generate-order-summary.investor-order.action';
import { inlineInvestorOrderActions } from './grid-actions/inline.investor-order.action';
import { openIoEntryInvestorOrderAction } from './grid-actions/open-io-entry.investor-order.action';
import { openPrincipalFillAction } from './grid-actions/open-principal-fill.investor-order.action';
import { openCrossPrincipalFillAction } from './grid-actions/open-cross-principal-fill';
import { openEntryTradingOrderAction } from '../trading-order-monitor/grid-actions/open-entry.trading-order.action';
import type { TypedInvestorOrderGridProps } from './investor-order-monitor.widget.config';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { reactivateInvestorOrderAction } from './grid-actions/reactivate.investor-order.action';
import { useCurrentAppWidgetActorProps } from '@app/common/workspace/workspace.hooks';
import { investorOrderMasterDetail } from './investor-order-monitor.master-detail';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { createInstrumentFollowEventHandler } from '@app/data-access/services/system/instrument-follow/instrument-follow.grid.event-handler';
import { IOHasExecutedQuantity, IONoExecutedQuantity, IOPending } from '../utils/row-state-rule-utils';
import { setupGridActions } from '@app/actions/grids/setup.configurable.actions';
import { createRouteOrderAction } from '../route-order/commands/route-order/route-order.action';
import { APP_CELL_RENDERERS } from '@app/common/grids/cell-renderers/app-renderers';
import { TsInvestorOrdersWithChargesDocument } from './investor-order-monitor.contracts';
import type {
  InvestorOrderWithChargesRow,
  TsInvestorOrdersWithChargesSubscription
} from './investor-order-monitor.contracts';
import { createCreateInvestorOrderAction } from '../investor-order-entry/commands/create-investor-order/create-investor-order.action';
import { createModifyInvestorOrderAction } from '../investor-order-entry/commands/modify-investor-order/modify-investor-order.action';
import { INVESTOR_ORDER_MONITOR_GRID_NAME } from '../investor-order-entry/investor-order-entry.constants';
import { createViewInvestorOrderAction } from '../investor-order-view/commands/view-investor-order/view-investor-order.action';
import { createDoneForDayAction } from './commands/done-for-day/done-for-day.action';
import { createAcceptInvestorOrderAction } from './commands/accept-investor-order/accept-investor-order.action';
import { createRejectInvestorOrderAction } from './commands/reject-investor-order/reject-investor-order.action';
import { createCancelInvestorOrderAction } from './commands/cancel-investor-order/cancel-investor-order.action';

export const InvestorOrderMonitorWidgetComponent = () => {
  const componentProps = useCurrentAppWidgetActorProps<TypedInvestorOrderGridProps>()[0];

  useInvestorOrderMonitorToolbarActions();

  const datasourceService = useService(TableServerDatasourceService);
  const gridProps = useVGrid<InvestorOrderWithChargesRow>(
    'investor-order-monitor',
    (builder) =>
      setupGridActions({
        meta: {
          widgetTypeId: INVESTOR_ORDER_MONITOR_GRID_NAME,
          allowedCommands: [
            'accept_investor_order',
            'cancel_investor_order',
            'create_investor_order',
            'done_for_day',
            'modify_investor_order',
            'reject_investor_order',
            'route_order',
            'view_investor_order'
          ]
        },
        grid: builder
          .tableServerColumnLibrary(
            buildInvestorOrderMonitorColumnDefs(componentProps?.nonfilterable, componentProps?.excluded)
          )
          .rowSelection((c) => c.multiple())
          .datasource((d) =>
            d
              .source(
                datasourceService.getSource<
                  InvestorOrderWithChargesRow,
                  TsInvestorOrdersWithChargesSubscription
                >({
                  query: TsInvestorOrdersWithChargesDocument,
                  getData: (r) => r.tsInvestorOrdersWithFilter
                })
              )
              .rowId((r) => r.data.id)
              .cacheBlockSize(100)
          )
          .marketData(investorOrderMarketData)
          .detailGrid(investorOrderMasterDetail)
          .injectEvents([
            OrderMonitorVisibilityEventHander,
            GridConfigEventHandler,
            createInstrumentFollowEventHandler<InvestorOrderWithChargesRow>({
              sourceType: INVESTOR_ORDER_MONITOR_GRID_NAME,
              getId: (data) => data.instrument,
              getDisplayCode: (data) => data.instrumentDisplayCode,
              colId: 'instrumentDisplayCode'
            })
          ])
          .rowStateRules({
            pending: (params) => IOPending(params.data),
            noExecutedQuantity: (params) => IONoExecutedQuantity(params.data),
            hasExecutedQuantity: (params) => IOHasExecutedQuantity(params.data)
          })
          .sideBar()
          .actions((a) =>
            a
              .schema((s) =>
                s
                  .action(openIoEntryInvestorOrderAction('create'))
                  .action(openEntryTradingOrderAction())
                  .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                  .action(createViewInvestorOrderAction('context-menu'))
                  .action(createModifyInvestorOrderAction('context-menu'))
                  .action(openCrossPrincipalFillAction())
                  .action(openPrincipalFillAction())
                  .action(investorOrderGenerateOrderSummaryAction())
                  .action(createDoneForDayAction('context-menu'))
                  .action(createAcceptInvestorOrderAction('context-menu'))
                  .action(createRejectInvestorOrderAction('context-menu'))
                  .action(createRouteOrderAction('context-menu'))
                  .action(createCreateInvestorOrderAction('context-menu'))
                  .action(openIoEntryInvestorOrderAction('update'))
                  .action(reactivateInvestorOrderAction())
                  .action(createCancelInvestorOrderAction('context-menu'))
                  .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                  .action(inlineInvestorOrderActions())
                  .action(refreshServersideCustomMenuAction)
              )
              .settings((s) => s.fromFile({}))
          )
          .reactiveCustomComponents()
          .registerAppCellRenderers(APP_CELL_RENDERERS)
      }),
    [datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default InvestorOrderMonitorWidgetComponent;
