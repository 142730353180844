import { inject, injectable } from 'tsyringe';
import {
  scan,
  map,
  take,
  merge,
  bufferTime,
  concatMap,
  of,
  filter,
  catchError,
  switchMap,
  share
} from 'rxjs';
import type { Observable } from 'rxjs';
import type {
  TableServerRow,
  TableServerSubscriptionShape,
  TableServerSubscriptionInnerShape,
  TableServerRowSubscriptionVariables
} from './table-server.datasource.contracts';
import type { DocumentNode } from '@apollo/client';
import type { GraphQLError } from 'graphql';
import { applyPatch } from 'fast-json-patch';
import type { AgGridFilterModelUnion } from './filters/ag-grid.filters.schema';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { Logger } from '@oms/shared/util';

const tableServerLogger = Logger.named('TableServerService');

const PATCH_BUFFER_TIME_MS = 50;

export type TableServerQueryFilter<TData extends TableServerRow> = Partial<
  Record<keyof TData, AgGridFilterModelUnion>
>;
export interface TableServerQueryOptions<
  TData extends TableServerRow,
  TSubscription extends TableServerSubscriptionShape<TData>
> {
  /** The query to use for the table server */
  query: DocumentNode;
  /** Mapper function to find & map rows to inner data */
  getData: (result: TSubscription) => TableServerSubscriptionInnerShape<TData> | undefined;
  /** The variables to use for the table server query */
  variables: TableServerRowSubscriptionVariables;
  filter?: TableServerQueryFilter<TData>;
  bufferTimeMs?: number;
}

export type TableServerQueryInnerData<TData> = {
  rows?: TData[];
  patch?: string;
  queryInfo: {
    totalCount: number;
    queryCount: number;
  };
};

export type TableServerQueryResult<TData> = {
  errors?: readonly GraphQLError[] | Error[] | undefined;
  rows?: TData[];
  totalCount: number;
};

@injectable()
export class TableServerService {
  constructor(@inject(RxApolloClient) private apolloClient: RxApolloClient) {}

  /**
   * Observable that subscribes to a table server query
   *
   * @param options {TOptions extends TableServerQueryOptions<TSubscription, TData>}
   * @returns {Observable<TableServerQueryResult<TData>>}
   */
  public query$<TData extends TableServerRow, TSubscription extends TableServerSubscriptionShape<TData>>({
    query,
    variables,
    getData,
    bufferTimeMs = PATCH_BUFFER_TIME_MS
  }: TableServerQueryOptions<TData, TSubscription>): Observable<TableServerQueryResult<TData>> {
    // Share the subscription to prevent multiple WS connections
    const subscription$ = this.apolloClient
      .rxSubscribe<TSubscription, TableServerRowSubscriptionVariables>({
        query,
        fetchPolicy: 'no-cache',
        variables
      })
      .pipe(share());

    // Get initial rows
    return subscription$.pipe(
      take(1),
      map((result) => {
        if (result.errors?.length) {
          return {
            ...TableServerService.EMPTY_RESULT,
            errors: result.errors
          };
        }

        const data = result.data && getData(result.data);
        return {
          ...TableServerService.EMPTY_RESULT,
          rows: data?.rows || [],
          totalCount: data?.queryInfo?.totalCount || 0
        };
      }),
      switchMap((initialState) => {
        // Handle patch updates
        const patchUpdates$ = subscription$.pipe(
          bufferTime(bufferTimeMs),
          filter((updates) => updates.length > 0),
          concatMap((updates) => {
            return of(updates).pipe(
              scan((acc, results) => {
                return results.reduce((currentState, result) => {
                  if (result.errors?.length) {
                    throw result.errors;
                  }

                  try {
                    const data = result.data && getData(result.data);
                    if (!data?.patch) {
                      return currentState;
                    }

                    const { patch, queryInfo } = data;
                    const totalCount = queryInfo?.totalCount || 0;
                    const patchObj = JSON.parse(patch);
                    const newRows = applyPatch(currentState.rows || [], patchObj).newDocument;

                    return {
                      ...TableServerService.EMPTY_RESULT,
                      rows: newRows,
                      totalCount
                    };
                  } catch (e) {
                    throw e;
                  }
                }, acc);
              }, initialState)
            );
          })
        );

        return merge(of(initialState), patchUpdates$);
      }),
      catchError((e) => {
        const errors = Array.isArray(e) ? e : [e];
        for (const error of errors) {
          tableServerLogger.error(error);
        }
        return of({ ...TableServerService.EMPTY_RESULT, errors });
      })
    );
  }

  /**
   * Empty result object for table server queries
   */
  static EMPTY_RESULT = {
    rows: [],
    errors: [],
    totalCount: 0
  };
}
