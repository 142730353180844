import { ColumnApi, type GetContextMenuItemsParams, GridApi } from '@ag-grid-community/core';
import { delay, inject, Lifecycle, scoped } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { ComponentService } from './component.service';
import { merge, remove as r } from 'lodash';
import { filter, Subject, type Subscription } from 'rxjs';
import { ContextMenuService } from './context-menu.service';
import { createLogger, isPromiseLike } from '@oms/shared/util';
import { ActionChangeEventFactory } from '../factories/actions/change-events/action.change.event.factory';
import type { AnyRecord, SimpleContextMenuItem } from '@oms/frontend-foundation';
import type { VGridContextInstance } from '../models/v.context.model';
import type {
  ActionsConfig,
  ActionDef,
  ActionMessage,
  ActionComponentConfig,
  ActionNotificationOptions,
  ActionComponentProps,
  SourcelessActionMessage,
  ActionStateChangeEvent,
  Action,
  ActionTypes,
  ActionMeta
} from '../models/actions.model';
import type { ContextMenuItem, MenuItemDefExtended } from '../models/grid-menu-options.model';
import { VComponent, ComponentLocationKeys, ComponentLocation } from '../models/v.component.model';
import { VGridInstance } from '../models/v.instance.model';
import { CustomContextMenuService } from './custom-context-menu.service';
import type { CustomContextMenuItem } from '../models/custom.context-menu.model';

@scoped(Lifecycle.ContainerScoped)
export class ActionsService<TData extends AnyRecord> {
  private _logger = createLogger({ name: 'ActionsService' });
  private _gridApi: GridApi<TData>;
  private _colApi: ColumnApi;
  private _gridContext: VGridContextInstance;
  private _gridContainer: DependencyContainer;
  private _appContainer: DependencyContainer;
  private _actionsConfig: ActionsConfig<TData>;
  private _componentService: ComponentService;
  private _actionsByRoot = new Map<string, Action<TData>[]>();
  private _actionDefs = new Map<string, ActionDef<TData>>();
  private _actions = new Map<string, Action<TData>>();
  private _actionsByType = new Map<ActionTypes, Action<TData>[]>();
  private _actionsHub: Subject<ActionMessage<TData>>;
  private _actionsSub?: Subscription;
  private _contextMenuSub?: Subscription;
  private _customContextMenuSub?: Subscription;
  private _contextMenuService: ContextMenuService<TData>;
  private _customContextMenuService: CustomContextMenuService<TData>;
  private _isFirstConfigRender = true;
  // rowID and actionIDs pairs that are in a loading state so the action should be
  // disabled in the context menu, mapped to an optional different label to indicate
  // the operation is in process
  private _rowActionLoading = new Map<string, string | undefined>();
  // TODO: grid menu service?

  constructor(
    @inject(VGridInstance.GridActionsConfig) actionsConfig: ActionsConfig<TData>,
    @inject(VGridInstance.GridActionEvents) actionEvents$: Subject<ActionMessage<TData>>,
    @inject(ComponentService) componentService: ComponentService,
    @inject(delay(() => GridApi)) gridApi: GridApi<TData>,
    @inject(delay(() => ColumnApi)) colApi: ColumnApi,
    @inject(VGridInstance.Context) context: VGridContextInstance,
    @inject(ContextMenuService) contextMenuService: ContextMenuService<TData>,
    @inject(CustomContextMenuService) customContextMenuService: CustomContextMenuService<TData>
  ) {
    this._actionsHub = actionEvents$;
    this._gridContext = context;
    this._contextMenuService = contextMenuService;
    this._customContextMenuService = customContextMenuService;

    this._gridContainer = context.container;
    this._appContainer = context.parentContainer;
    this._actionsConfig = actionsConfig;
    this._componentService = componentService;
    this._gridApi = gridApi;
    this._colApi = colApi;

    const schema = this._actionsConfig.schema;
    const availableActionKeys = Object.keys(schema || {});

    availableActionKeys.forEach((k) => {
      this._actionDefs.set(schema[k].name, schema[k]);
    });
  }

  protected generateActionId(action: ActionMeta<TData>) {
    const actionId = `${action.name}-${action.actionType}${action.instanceId ? `-${action.instanceId}` : ''}`;

    return actionId;
  }

  protected startRouter() {
    this._actionsSub = this.actions$().subscribe((e) => {
      const action = this.find(e.actionId);
      const actionDef = this._actionDefs.get(action?.name || '');

      // Usually row data passed with the context is the currently selected rows
      // Note that for context menu we programatically select the row right-clicked on
      let data = this._gridApi.getSelectedRows();

      if (action?.actionType === 'Inline') {
        // For inline buttons, we override this with the relevant row passed in the event
        data = e.data ?? [];
      }

      if (action?.actionType === 'CustomContextMenu' && e.data) {
        // For custom context menu items, we override this with the relevant row passed in the event
        data = e.data;
      }

      if (action && actionDef) {
        switch (e.source) {
          case 'component':
          case 'menu':
          case 'customMenu':
          case 'grid':
          case 'action': {
            if (
              !actionDef.lifecycles ||
              !actionDef.lifecycles.length ||
              actionDef.lifecycles.includes(e.lifecycle)
            ) {
              const canAccess: boolean = actionDef?.access
                ? actionDef.access({ appContainer: this._appContainer })
                : true;

              if (!canAccess) {
                return;
              }

              const result = action.onChange({
                data,
                workspace: this._gridContext.workspace,
                container: this._gridContainer,
                appContainer: this._appContainer,
                windowActor: this._gridContext.windowActor,
                widgetActor: this._gridContext.widgetActor,
                state: e.state,
                source: e.source,
                event: e.event,
                api: this._gridApi,
                columnApi: this._colApi,
                context: {},
                actionId: e.actionId,
                config: this._actionDefs.get(action.name)?.config || {},
                notify: ((n: ActionComponentConfig<TData>, opts?: ActionNotificationOptions<TData>) => {
                  this.processNotifications(
                    {
                      actionId: e.actionId,
                      source: 'user',
                      state: n,
                      lifecycle: e.lifecycle || 'change',
                      value: e.value
                    },
                    action.name,
                    opts
                  );
                }) as any,
                actionType: action.actionType,
                lifecycle: e.lifecycle || 'change',
                value: e.value
              });
              if (isPromiseLike(result)) {
                result.catch((err) => {
                  this._logger.error('Action failed', err);
                });
              }
            }
            break;
          }
        }
      }
    });
  }

  protected subToContextMenu() {
    this._contextMenuSub = this.actions$()
      .pipe(filter((e) => e.source === 'user' && ActionsService.parse(e.actionId).type === 'ContextMenu'))
      .subscribe((e) => {
        const { name } = ActionsService.parse(e.actionId);
        const actions = this.actions();
        const actionDef = actions.find((a) => a.name === name);

        if (!actionDef || !actionDef.menu) {
          return;
        }

        // The only state that interests us on the context menu of an action is the
        // isLoading one, which makes an option disabled if it's supposed to be
        // enabled for a specific line.
        // isDisabled and isVisible are computed on the fly in the ContextMenuItem's
        // callback when the right click is clicked
        // We keep this override here and clear it on rowDataUpdated

        const {
          actionId,
          state: { isLoading, loadingText, rowData }
        } = e;
        if (rowData && 'id' in rowData) {
          const key = actionAndRowIdPair(actionId, String(rowData.id));

          if (isLoading === false) {
            this._rowActionLoading.delete(key);
          } else if (isLoading === true) {
            this._rowActionLoading.set(key, loadingText);
          }
        }

        this.renderContextMenu(actionDef);
      });
  }

  public static parse(actionId: string) {
    let dashCount = 0;
    const result: { name?: string; type?: ActionTypes; instance?: string } = {};
    let currStr = '';
    for (const token of actionId) {
      if (token === '-' && dashCount < 2) {
        switch (dashCount) {
          case 0:
            result.name = currStr;
            break;
          case 1:
            result.type = currStr as ActionTypes;
            break;
        }

        currStr = '';
        dashCount++;
      } else {
        currStr += token;
      }
    }

    if (result.type) {
      result.instance = currStr;
    } else {
      result.type = currStr as ActionTypes;
    }

    return result;
  }

  /**
   * Allows adding and updating action definitions. Useful for button configs.
   * @param defs New or updated action definitions.
   */
  public upsertDefinitions(...defs: ActionDef<TData>[]) {
    if (this._isFirstConfigRender === false) {
      this._gridApi.deselectAll(); // resets the selection when action defs change to prevent buttons showing up for the wrong row
    }

    this._isFirstConfigRender = false;

    defs.forEach((def) => {
      this._actionDefs.set(def.name, def);
      def.components?.forEach((c) => {
        c.location &&
          this.register({
            name: def.name,
            id: '',
            actionType: c.location
          });
      });
    });
  }

  /**
   * Creates a new instance of an action based on its definition.
   * @param action Metadata about the new action to being rendered.
   * @returns The new action id.
   */
  public register(action: ActionMeta<TData>): string {
    const id = this.generateActionId(action);
    const actions = this.actions();
    const actionDef = actions.find((a) => a.name === action.name);

    if (!actionDef) {
      throw new Error('Missing action def for action.');
    }

    const component = (actionDef.components || []).find((c) => c.location?.toString() === action.actionType);

    const modifiedAction: Action<TData> = {
      onChange: actionDef.onChange,
      name: action.name,
      id,
      actionType: action.actionType,
      component: merge<
        VComponent<ActionComponentProps<TData>>,
        Partial<VComponent<Partial<ActionComponentProps<TData>>>>
      >(
        { ...((component || {}) as any) },
        {
          props: {
            onChange: ((e: SourcelessActionMessage<TData>) => {
              this.actions$().next({ ...e, source: 'component' });
            }) as any,
            notify: (n: ActionComponentConfig<TData>, opts?: ActionNotificationOptions<TData>) => {
              this.notify(n, action.name, opts);
            }
          }
        }
      )
    };

    this._actions.set(id, modifiedAction);

    const compsByType = this._actionsByType.get(action.actionType) || [];
    const existingIdx = compsByType.findIndex((c) => c.id === modifiedAction.id);

    if (existingIdx > -1) {
      compsByType[existingIdx] = modifiedAction;
    } else {
      compsByType.push(modifiedAction);
    }

    this._actionsByType.set(action.actionType, compsByType);

    const rootActions = this._actionsByRoot.get(action.name) || [];
    const existingRoot = rootActions.findIndex((r) => r.id === modifiedAction.id);

    if (existingRoot > -1) {
      rootActions[existingRoot] = modifiedAction;
    } else {
      rootActions.push(modifiedAction);
    }

    this._actionsByRoot.set(action.name, rootActions);

    return id;
  }

  public find(actionId: string): Action<TData> | undefined {
    return this._actions.get(actionId);
  }

  public remove(actionId: string | undefined) {
    if (!actionId) return;
    const action = this._actions?.get(actionId) ?? undefined;
    if (!action) return;

    action.component &&
      action.actionType !== 'Inline' &&
      action.actionType !== 'ContextMenu' &&
      action.actionType !== 'CustomContextMenu' &&
      this._componentService.removeFrom(ComponentLocation[action.actionType], [action.component.id]);

    action.actionType === 'ContextMenu' && this._contextMenuService.remove([action.id]);
    action.actionType === 'CustomContextMenu' && this._customContextMenuService.remove([action.id]);

    const actionsByType = this._actionsByType?.get(action?.actionType) ?? [];
    r(actionsByType, (actionByType) => actionByType.id === actionId);
    this._actionsByType?.set(action?.actionType, actionsByType);

    const actionsByRoot = this._actionsByRoot?.get(action?.name) ?? [];
    r(actionsByRoot, (actionByRoot) => actionByRoot.id === actionId);
    this._actionsByRoot?.set(action?.name, actionsByRoot);

    if (actionsByType?.length === 0) this._actionsByType?.delete(action?.actionType);
    if (actionsByRoot?.length === 0) this._actionsByRoot?.delete(action?.name);
    this._actions?.delete(actionId);
  }

  public removeDefinitions(...defs: ActionDef<TData, AnyRecord>[]): void {
    defs.forEach((d) => {
      const actions = this._actionsByRoot.get(d.name);
      if (actions?.length) {
        actions.forEach((a) => {
          this.remove(a.id);
        });
      }
      this._actionDefs.delete(d.name);
    });
  }

  public async updateState(e: ActionStateChangeEvent) {
    // TODO: Breaks ESLint server
    // This function is called from the actions event handler as a
    // response to grid events such as row selection change etc.
    // Because context menu items are decided only once right click
    // is clicked, we don't want to send them these updates which
    // will trigger on change and cause notify to override the
    // behaviour in menu's visible() or disabled()

    const actions = Array.from(this._actions)
      .map((a) => a[1])
      .filter(({ actionType }) => !(actionType === 'ContextMenu' && e.lifecycle !== 'init'));

    // perhaps we could make this user overridable at some point?
    // add a serialize function to interface?
    const result = await ActionChangeEventFactory.create<TData>(e.lifecycle).execute(actions, e);

    result.forEach((a) => {
      this.actions$().next({
        actionId: a.id,
        source: 'grid',
        lifecycle: e.lifecycle,
        event: e.event,
        state: {} // store current action prop state in service?
      });
    });
  }

  public actions(): ActionDef<TData>[] {
    return Array.from(this._actionDefs.values());
  }

  public render() {
    const actions = this.actions();
    actions.forEach((action) => {
      if (action.components) {
        this.renderComponents(action, action.components);
      }

      if (action.menu) {
        this.renderContextMenu(action);
      }
    });

    // Render all the actions in one go, because it's an expensive operation
    const customContextMenuActions = actions.filter((a) => a.customMenu);
    this.renderCustomContextMenu(customContextMenuActions);
  }

  protected processNotifications(
    msg: ActionMessage<TData>,
    actionRoot: string,
    opts?: ActionNotificationOptions<TData>
  ) {
    processAction({ ...msg, actionRoot, ...(opts || {}), events$: this.actions$() });
  }

  protected notify(
    state: ActionComponentConfig<TData>,
    rootAction: string,
    opts?: ActionNotificationOptions<TData>
  ) {
    this.processNotifications(
      {
        actionId: '',
        source: 'action',
        state,
        lifecycle: 'change'
      },
      rootAction,
      opts
    );
  }

  protected renderComponents(
    action: ActionDef<TData>,
    components: VComponent<ActionComponentProps<TData>>[]
  ) {
    components.forEach((c) => {
      if (!c.location) {
        return;
      }

      const comp: VComponent<ActionComponentProps<TData>> = c as VComponent<ActionComponentProps<TData>>;

      if (comp.location) {
        const canAccess: boolean = action.access ? action.access({ appContainer: this._appContainer }) : true;

        if (!canAccess) {
          return;
        }

        comp.props = merge(comp.props || {}, {
          id: '',
          name: action.name,
          actionType: comp.location.toString() as ComponentLocationKeys,
          config: action.config,
          onChange: (e: SourcelessActionMessage<TData>) => {
            this.actions$().next({ ...e, source: 'component' });
          },
          notify: (n: ActionComponentConfig<TData>, opts?: ActionNotificationOptions<TData>) => {
            this.notify(n, action.name, opts);
          }
        });

        this._componentService.appendTo(comp.location, [comp]);

        this.processNotifications(
          {
            state: { ...comp.props },
            lifecycle: 'change',
            source: 'user',
            actionId: this.generateActionId({
              actionType: comp.location,
              instanceId: comp?.props?.instanceId,
              id: '',
              name: action.name
            })
          },
          action.name
        );
      }
    });
  }

  protected renderContextMenu(action: ActionDef<TData>) {
    if (!action.menu) {
      return;
    }

    const id = this.register({
      id: '',
      name: action.name,
      actionType: 'ContextMenu'
    });

    if (this.find(id) && action.menu.visible === false) {
      this._logger.debug('context menu item', id, 'is no longer visible');

      this._contextMenuService.remove([id]);

      return;
    }

    const item: ContextMenuItem<TData> = {
      id,
      callback: (params: GetContextMenuItemsParams<TData>): string | MenuItemDefExtended | undefined => {
        if (!action.menu) {
          return undefined;
        }

        const canAccess: boolean = action.access ? action.access({ appContainer: this._appContainer }) : true;

        const key = actionAndRowIdPair(id, String(params.node?.data?.id));
        const { disabled, visible } = action.menu;

        const loadingOverride = this._rowActionLoading.has(key);
        const nameWhileLoading = this._rowActionLoading.get(key);
        const name = nameWhileLoading ?? action.menu.name;

        const isDisabled =
          loadingOverride ||
          (typeof disabled === 'function'
            ? disabled({
                gridApi: this._gridApi,
                appContainer: this._appContainer,
                rowData: params.node?.data || ({} as TData)
              })
            : disabled);
        const isVisible = canAccess
          ? typeof visible === 'function'
            ? visible({
                gridApi: this._gridApi,
                appContainer: this._appContainer,
                rowData: params.node?.data || ({} as TData)
              })
            : (visible ?? true)
          : false;

        if (!isVisible) {
          return undefined;
        }

        return {
          ...action.menu,
          disabled: isDisabled,
          name,
          action: () => {
            this.actions$().next({
              actionId: id,
              source: 'menu',
              state: {},
              data: [params.node?.data].filter(Boolean) as TData[],
              lifecycle: 'change'
            });
          }
        };
      }
    };

    this._contextMenuService.upsert([item]);
  }

  protected renderCustomContextMenu(action: ActionDef<TData> | ActionDef<TData>[]) {
    const actions = Array.isArray(action) ? action : [action];

    const contextMenuItems: CustomContextMenuItem<TData>[] = [];

    for (const action of actions) {
      if (!action.customMenu) {
        break;
      }

      const id = this.register({
        id: '',
        name: action.name,
        actionType: 'CustomContextMenu'
      });

      const canAccess: boolean = action.access ? action.access({ appContainer: this._appContainer }) : true;

      const item: CustomContextMenuItem<TData> = {
        id,
        tabName: action.customMenu.tabName,
        label: typeof action.customMenu.label === 'string' ? action.customMenu.label : action.name,
        onClick: () => {},
        disabled: action.customMenu.disabled,
        visible: action.customMenu.visible,
        primary: action.customMenu.primary,
        priority: action.customMenu.priority,
        callback: (data?: TData[]): Partial<SimpleContextMenuItem> => {
          const rowData = (data || []).filter(Boolean) as TData[];
          if (!action.customMenu) {
            return {};
          }

          const { disabled, visible, primary, priority, label: _label } = action.customMenu;
          const name = action.customMenu.id;

          const label =
            typeof _label === 'function'
              ? _label({
                  gridApi: this._gridApi,
                  appContainer: this._appContainer,
                  rowData
                })
              : _label || name;

          const isDisabled =
            typeof disabled === 'function'
              ? disabled({
                  gridApi: this._gridApi,
                  appContainer: this._appContainer,
                  rowData
                })
              : disabled;

          const isVisible = canAccess
            ? typeof visible === 'function'
              ? visible({
                  gridApi: this._gridApi,
                  appContainer: this._appContainer,
                  rowData
                })
              : (visible ?? true)
            : false;

          const isPrimary =
            typeof primary === 'function'
              ? primary({
                  gridApi: this._gridApi,
                  appContainer: this._appContainer,
                  rowData
                })
              : (primary ?? false);

          const order =
            typeof priority === 'function'
              ? priority({
                  gridApi: this._gridApi,
                  appContainer: this._appContainer,
                  rowData
                })
              : (priority ?? undefined);

          return {
            isDisabled,
            isVisible,
            isPrimary,
            label,
            order,
            onClick: () => {
              this.actions$().next({
                actionId: id,
                source: 'customMenu',
                state: {},
                data: rowData,
                lifecycle: 'change'
              });
            }
          };
        }
      };

      contextMenuItems.push(item);
    }

    this._customContextMenuService.upsertCustom(contextMenuItems);
  }

  public start() {
    this.startRouter();
    this.subToContextMenu();
    this.render();

    // Run on next event loop to give event handlers a chance to register
    setTimeout(() => {
      this._gridApi.dispatchEvent({
        type: 'actionsReady'
      });
    }, 0);
  }

  public destroy() {
    this._contextMenuSub?.unsubscribe();
    this._customContextMenuSub?.unsubscribe();
    this._actionsSub?.unsubscribe();

    this._logger.debug('destroying actions');

    this._actionsByType.forEach((as, l) => {
      if (l === 'Inline' || l === 'ContextMenu' || l === 'CustomContextMenu') return;

      const loc: ComponentLocation = ComponentLocation[l as ComponentLocationKeys];
      // TODO: Breaks ESLint server
      const filtered = as.filter((a) => !!a.component);
      const ids = filtered.map((a) => a.id);
      this._componentService.removeFrom(loc, ids);
    });

    // TODO: Clear custom context menu items?
  }

  public actions$() {
    return this._actionsHub;
  }
}

function actionAndRowIdPair(actionId: string, rowId: string) {
  return `${actionId}-${rowId}`;
}

export type ProcessAction<TData extends AnyRecord = AnyRecord> = ActionMessage<TData> &
  ActionNotificationOptions<TData> & {
    actionRoot: string;
    events$: Subject<ActionMessage<TData>>;
    actions?: Action<TData>[];
  };

export type NotifyAction<TData extends AnyRecord = AnyRecord> = ActionComponentConfig<TData> &
  Pick<ProcessAction<TData>, 'actionRoot' | 'events$'> &
  ActionNotificationOptions<TData>;

export const notifyComponent = <TData extends AnyRecord = AnyRecord>(opts: NotifyAction<TData>) => {
  const { actionRoot, events$, ...rest } = opts;
  processAction({
    actionId: '',
    source: 'action',
    state: rest,
    lifecycle: 'change',
    actionRoot,
    events$
  });
};

export const processAction = <TData extends AnyRecord = AnyRecord>(opts: ProcessAction<TData>) => {
  const { targets = [], events$, actionRoot, actions = [] } = opts;

  if (!targets?.length) {
    events$.next(opts);
    return;
  }

  targets.forEach(({ actionName = actionRoot, locations, rowId, rowsData }) => {
    actions.forEach(({ name, actionType, id }) => {
      const matchingActionName = name === actionName;
      const matchingLocations = !locations || locations.length === 0 || locations.includes(actionType);
      const matchingRowId = !rowId || ActionsService.parse(id).instance === rowId;

      if (matchingActionName && matchingLocations && matchingRowId) {
        events$.next({ ...opts, actionId: id, data: rowsData ?? opts.data, source: 'action' });
      }
    });
  });
};
