import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import type { MappedNotificationSettings } from '@app/widgets/user/user-preferences/preferences/notification-settings/notification-settings.contracts';
import { sharedDefaultCol, sharedTextCol } from '@app/common/grids/columns/generic-cols';

type NotificationSettingsColumnBuilder = ColumnBuilderCallback<MappedNotificationSettings>;

const defaultCol: NotificationSettingsColumnBuilder = (c) =>
  sharedDefaultCol(c).flex(1).colDef({
    editable: true
  });

const notificationNameCol: NotificationSettingsColumnBuilder = (c) =>
  sharedTextCol<MappedNotificationSettings>(c, 'notificationName').header('Event').flex(1.5).colDef({
    editable: false
  });

const platformPopupCol: NotificationSettingsColumnBuilder = (c) =>
  c
    .field('isPopup')
    .colDef({
      cellEditor: 'agCheckboxCellEditor', // CT: this was previously agNumberCellEditor. Ask if that was intentional.
      cellRenderer: 'agCheckboxCellRenderer',
      editable: (params) => params.data?.isPopupEnabled || false
    })
    .header('Platform popup');

const soundCol: NotificationSettingsColumnBuilder = (c) =>
  c
    .field('isSound')
    .colDef({
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
      editable: (params) => params.data?.isSoundEnabled || false
    })
    .header('Sound');

const showCol: NotificationSettingsColumnBuilder = (c) =>
  c
    .field('isShown')
    .colDef({
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
      editable: (params) => params.data?.isShownEnabled || false
    })
    .flex(2)
    .header('Show in notification center');

export const notificationsSettingsColumnLibrary: ColumnLibrary<MappedNotificationSettings> = {
  defaultColumn: defaultCol,
  columns: [notificationNameCol, platformPopupCol, soundCol, showCol]
};
