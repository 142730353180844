import type { AnyRecord, Optional } from '@oms/shared/util-types';
import { Result } from '@oms/shared/util';
import { convertAlert } from '@oms/frontend-foundation';
import type { FeedbackWrapper } from '@oms/frontend-foundation';
import { t } from '@oms/codegen/translations';
import type { ActionContext } from '@oms/frontend-vgrid';
import type { PartialDialogDefinition } from '@app/generated/sdk';
import { openConfirmation } from '@app/generated/sdk';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import type { DialogDictTypeMap } from '@app/generated/common';
import { isHotButton } from './common.util';

export type ConfirmationErrorReason = 'User canceled' | 'Task failure';

export abstract class ConfirmationError extends Error {
  public reason: ConfirmationErrorReason;

  protected constructor(reason: ConfirmationErrorReason) {
    super(reason);
    this.reason = reason;
    Object.setPrototypeOf(this, ConfirmationError.prototype);
  }
}

export class UserCanceledError extends ConfirmationError {
  public constructor() {
    super('User canceled');
    Object.setPrototypeOf(this, UserCanceledError.prototype);
  }
}

export class TaskFailureError<TError> extends ConfirmationError {
  public error: TError;

  public constructor(error: TError) {
    super('Task failure');
    this.error = error;
    Object.setPrototypeOf(this, UserCanceledError.prototype);
  }
}

export type OnConfirmCallbackFn<
  TResultValue,
  TError,
  TData extends AnyRecord,
  TConfig extends AnyRecord = AnyRecord
> = (ctx: ActionContext<TData, TConfig>) => Promise<Result<TResultValue, TError>>;

type DialogConfig = PartialDialogDefinition<DialogDictTypeMap['CONFIRMATION']>;

export interface RunConfirmationButtonSetup<
  TResultValue,
  TError,
  TData extends AnyRecord,
  TConfig extends AnyRecord = AnyRecord
> {
  ctx: ActionContext<TData, TConfig>;
  dialogConfig?: DialogConfig;
  onConfirm: OnConfirmCallbackFn<TResultValue, TError, TData, TConfig>;
  allowRetryOnFailures?: boolean;
  isRetry?: boolean;
  error?: TError;
  getFeedback?: (error: TError) => FeedbackWrapper[];
}

export async function runConfirmationButton<
  TResultValue,
  TError,
  TData extends AnyRecord,
  TConfig extends AnyRecord = AnyRecord
>(
  setup: RunConfirmationButtonSetup<TResultValue, TError, TData, TConfig>
): Promise<Result<TResultValue, ConfirmationError>> {
  const { ctx, onConfirm, dialogConfig, isRetry, allowRetryOnFailures } = setup;

  const confirmDialogConfig = isRetry ? dialogConfigForRetry(setup, dialogConfig) : dialogConfig;

  const isConfirmed = await waitForConfirmation(ctx, confirmDialogConfig);

  if (!isConfirmed) {
    return Result.failure(new UserCanceledError());
  }

  const result = await onConfirm(ctx);

  return result.mapToAsync(
    async (value) => {
      return Result.success(value);
    },
    async (error) => {
      if (!allowRetryOnFailures) {
        return Result.failure(new TaskFailureError(error));
      }
      return await runConfirmationButton({
        ctx,
        onConfirm,
        dialogConfig,
        allowRetryOnFailures,
        isRetry: true,
        error,
        getFeedback: setup.getFeedback
      });
    }
  );
}

function dialogConfigForRetry<TError>(
  setup: Pick<RunConfirmationButtonSetup<any, TError, any, any>, 'error' | 'getFeedback'>,
  dialogConfig: Optional<DialogConfig>
): DialogConfig {
  const { error, getFeedback } = setup;
  const { componentProps, ...rest } = dialogConfig || {};
  const {
    alerts: baseAlerts,
    message = t('app.common.dialogs.retryRequest'),
    confirmButtonText,
    ...otherProps
  } = componentProps || {};
  const alerts =
    error && getFeedback
      ? getFeedback(error).map((feedback) =>
          convertAlert.formValidationAlertItem.item(feedback).toAlertBannerStackItem()
        )
      : baseAlerts;
  return {
    ...rest,
    componentProps: {
      ...otherProps,
      alerts,
      message,
      confirmButtonText: t('app.common.retry')
    }
  };
}

async function waitForConfirmation<TData extends AnyRecord, TConfig extends AnyRecord = AnyRecord>(
  ctx: ActionContext<TData, TConfig>,
  confirmDialogConfig?: DialogConfig
): Promise<boolean> {
  const { container, workspace } = ctx;
  const windowId = getLeaderOrTabId(container);

  if (isHotButton(ctx)) {
    return true;
  }

  const [_, api] = await openConfirmation(workspace, windowId, confirmDialogConfig);
  const { type } = await api.awaitFirstEvent;

  return type === DIALOG_EVENT_TYPE.OK;
}
