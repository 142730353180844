import type { ICellRendererParams } from '@ag-grid-community/core';
import { Text } from '@oms/shared-frontend/ui-design-system';

export const PNL_CELL_RENDERER = 'PnlCellRenderer';

export const PnlCellRenderer: React.FC<ICellRendererParams> = ({ value }) => {
  const color =
    typeof value === 'number'
      ? value > 0
        ? 'price.positive'
        : value < 0
          ? 'price.negative'
          : undefined
      : undefined;

  return <Text sx={{ color }}>{value}</Text>;
};
