import { createLogger } from '@oms/shared/util';
import { EMPTY_COMMAND_PALETTE_CONTEXT } from '@app/common/command-palette/command-palette.contracts';
import type {
  CommonCommandPaletteActorSchema,
  CommandPaletteOpenOptions
} from '@app/common/command-palette/command-palette.contracts';
import type { Subscription } from 'rxjs';

/**
 * Logger for the Command Palette Actor
 */
export const commandPaletteActorLogger = createLogger({ name: 'Command Palette Actor' });

/**
 * Common Schema to be used by both browser and tauri actors
 */
type CommonCommandPaletteActorSchemaOverrides = (
  prev: Omit<CommonCommandPaletteActorSchema, 'view'>
) => Partial<Omit<CommonCommandPaletteActorSchema, 'view'>>;

export const commonCommandPaletteSchemaOverrides: CommonCommandPaletteActorSchemaOverrides = (
  prevSchema
) => ({
  ignoreFromTakeSnapshot: true,
  ignoreFromApplySnapshot: true,
  initialContext: async (ctx) => ({
    ...(await prevSchema.initialContext(ctx)),
    title: 'Command Palette',
    alwaysOnTop: true,
    isVisible: true,
    isDecorated: false,
    width: 600,
    height: 400,
    x: -10_000,
    y: -10_000,
    isResizable: false,
    transparent: true,
    isClosable: false,
    skipTaskbar: true,
    isMaximizable: false,
    isMinimizable: false,
    ...EMPTY_COMMAND_PALETTE_CONTEXT
  }),
  syncOnStart: false,
  sync: () => {
    // noop. Do not sync.
  },
  operations: (api, workspace) => {
    const prevOperations = prevSchema.operations(api, workspace);
    return {
      ...prevOperations,
      openPalette: async (options: CommandPaletteOpenOptions = {}) => {
        await api.updateContext(options);
        await prevOperations.centerInActiveMonitor();
        await prevOperations.show();
        await prevOperations.focus();
      },
      updatePalette: async (options: CommandPaletteOpenOptions) => {
        await api.updateContext(options);
      },
      closePalette: async () => {
        await prevOperations.hide();
        await api.updateContext(EMPTY_COMMAND_PALETTE_CONTEXT);
      }
    };
  },
  /**
   * Note: Here we're using the `events` hook to subscribe to the `open$`, `update$` and `close$` observables
   * from the actor meta. This is because we want to be able to open, update and close the command palette
   * from BroadcastSubjects anywhere in the app.
   */
  events: async (ctx, workspace) => {
    const unsubPrevEvents = await prevSchema.events?.(ctx, workspace);
    const subscriptions: Subscription[] = [];
    const actor = workspace
      .getActorRegistry()
      .getActorByName<CommonCommandPaletteActorSchema>(ctx.getDefinition().name);

    const open$ = actor?.meta?.open$;
    const update$ = actor?.meta?.update$;
    const close$ = actor?.meta?.close$;

    if (open$) {
      subscriptions.push(
        open$.subscribe((options) => {
          ctx.operations.openPalette(options).catch(console.error);
        })
      );
    }

    if (update$) {
      subscriptions.push(
        update$.subscribe((options) => {
          ctx.operations.updatePalette(options).catch(console.error);
        })
      );
    }

    if (close$) {
      subscriptions.push(
        close$.subscribe(() => {
          ctx.operations.closePalette().catch(console.error);
        })
      );
    }

    return () => {
      if (unsubPrevEvents) {
        unsubPrevEvents();
      }

      subscriptions.forEach((sub) => sub.unsubscribe());
    };
  }
});
