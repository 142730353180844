import type { ColDef } from '@ag-grid-community/core';
import { t } from '@oms/codegen/translations';
import type { Maybe } from '@oms/shared/util-types';
import { sortingFunction } from '@oms/shared/util';
import type { ColumnBuilderCallback } from '@oms/frontend-vgrid';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import instrumentColumnRenderer from '../renderers/instrument-column-renderer';
import { instrumentCols } from '@app/common/grids/columns/instrument-cols';
import {
  sharedCurrencyCol,
  sharedDateTimeCol,
  sharedDecimalCol,
  sharedDefaultCol,
  sharedNumericCol,
  sharedQuantityCol
} from '@app/common/grids/columns/generic-cols';
import { sharedNameCol } from '@app/common/grids/columns/reference-data-cols';
import { sharedPositionsNumeric, sharedPositionsPrice } from '@app/common/grids/columns/positions-cols';
import type ServerSideRowDataHelper from '@app/data-access/services/trading/positions/common/tree-grid/helpers/server-side-row-data-helper.abstract.class';
import type { TreeDataSlice } from '@app/data-access/services/trading/positions/common/tree-grid/types/tree-data.types';
import { buildSetFilter } from '@app/data-access/services/trading/positions/common/tree-grid/util/filtering/grid-filter-values.util';

type PositionsColumn = ColumnBuilderCallback<TreeDataSlice<PositionRow>>;
type InstrumentColRecord = Record<'displayCode' | 'longName', PositionsColumn>;

type ServerSideColCreator = (dataHelper?: ServerSideRowDataHelper<PositionRow>) => PositionsColumn;
type InstrumentServerSideColCreator = (
  dataHelper?: ServerSideRowDataHelper<PositionRow>
) => InstrumentColRecord;

const [instrumentDisplayCode, instrumentLongName] = instrumentCols<TreeDataSlice<PositionRow>>({
  include: ['displayCode', 'longName']
});

const sortByAbsoluteValue: ColDef<TreeDataSlice<PositionRow>>['comparator'] = (a, b, _, __, isDescending) =>
  sortingFunction.number.byAbsoluteValue(isDescending ? 'descending' : 'ascending')(
    a as Maybe<number>,
    b as Maybe<number>
  );

export const defaultColumn: PositionsColumn = (c) => sharedDefaultCol(c);

export const id: PositionsColumn = (c) => sharedNameCol(c, 'id');

export const name: PositionsColumn = (c) => sharedNameCol(c, 'name');

// instrument.xxxxx ----- /

const instrumentShared: InstrumentColRecord = {
  displayCode: (c) =>
    instrumentDisplayCode(c)
      .field('instrument.mappings.displayCode')
      .header(t('app.common.instrument'))
      .shortHeader(t('app.common.instrument', { ns: 'short' }))
      .cell((cb) => cb.renderer(instrumentColumnRenderer)),
  longName: (c) =>
    instrumentLongName(c)
      .field('instrument.longName')
      .header(t('app.positions.instrument.name'))
      .shortHeader(t('app.positions.instrument.name', { ns: 'short' }))
      .cell((cb) => cb.renderer(instrumentColumnRenderer))
};

export const instrument: InstrumentColRecord = {
  displayCode: (c) => instrumentShared.displayCode(c).filter(true),
  longName: (c) => instrumentShared.longName(c).filter(true)
};

export const instrumentServerSide: InstrumentServerSideColCreator = (dataHelper) => ({
  displayCode: (c) =>
    instrumentShared.displayCode(c).pipe((c) =>
      buildSetFilter(c, dataHelper, {
        getValue: ({ instrument }) => instrument?.mappings?.displayCode ?? ''
      })
    ),
  longName: (c) =>
    instrumentShared.longName(c).pipe((c) =>
      buildSetFilter(c, dataHelper, {
        getValue: ({ instrument }) => instrument?.mappings?.displayCode ?? ''
      })
    )
});

// account.name ----- /

const accountNameShared: PositionsColumn = (c) =>
  sharedNameCol(c, 'account.name')
    .header(t('app.positions.account'))
    .shortHeader(t('app.positions.account', { ns: 'short' }));

export const accountName: PositionsColumn = (c) => accountNameShared(c).filter(true);

export const accountNameServerSide: ServerSideColCreator = (dataHelper) => (c) =>
  accountNameShared(c).pipe((c) =>
    buildSetFilter(c, dataHelper, { getValue: ({ account }) => account?.name ?? '' })
  );

// accountCurrency.id ----- /

const accountCurrencyIdShared: PositionsColumn = (c) =>
  sharedCurrencyCol(c, 'accountCurrency.id')
    .header(t('app.positions.accountCurrency'))
    .shortHeader(t('app.positions.accountCurrency', { ns: 'short' }));

export const accountCurrencyId: PositionsColumn = (c) => accountCurrencyIdShared(c).filter(true);

export const accountCurrencyIdServerSide: ServerSideColCreator = (dataHelper) => (c) =>
  accountCurrencyIdShared(c)
    .pipe((c) =>
      buildSetFilter(c, dataHelper, { getValue: ({ accountCurrency }) => accountCurrency?.id ?? '' })
    )
    .filter(false)
    .sortable(false);

// ----- /

export const quantity: PositionsColumn = (c) =>
  sharedQuantityCol(c, 'quantity')
    .header(t('app.positions.quantity'))
    .shortHeader(t('app.positions.quantity', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .filter(false)
    .sortable(false);

// currency.id ----- /

const currencyIdShared: PositionsColumn = (c) =>
  sharedCurrencyCol(c, 'currency.id')
    .header(t('app.positions.currency'))
    .shortHeader(t('app.positions.currency', { ns: 'short' }))
    .width(80);

export const currencyId: PositionsColumn = (c) => currencyIdShared(c).filter(true);

export const currencyIdServerSide: ServerSideColCreator = (dataHelper) => (c) =>
  currencyIdShared(c).pipe((c) =>
    buildSetFilter(c, dataHelper, { getValue: ({ currency }) => currency?.id ?? '' })
  );

// ----- /

export const longPosition: PositionsColumn = (c) =>
  sharedNumericCol(c, 'longPosition')
    .header(t('app.positions.longPosition'))
    .shortHeader(t('app.positions.longPosition', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .filter(false)
    .sortable(false);

export const shortPosition: PositionsColumn = (c) =>
  sharedNumericCol(c, 'shortPosition')
    .header(t('app.positions.shortPosition'))
    .shortHeader(t('app.positions.shortPosition', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .filter(false)
    .sortable(false);

export const netPosition: PositionsColumn = (c) =>
  sharedPositionsNumeric(c, 'netPosition')
    .header(t('app.positions.netPosition'))
    .shortHeader(t('app.positions.netPosition', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .filter(false)
    .sortable(false);

export const absolutePosition: PositionsColumn = (c) =>
  sharedPositionsNumeric(c, 'absolutePosition')
    .header(t('app.positions.absolutePosition'))
    .shortHeader(t('app.positions.absolutePosition', { ns: 'short' }));

export const longExposure: PositionsColumn = (c) =>
  sharedNumericCol(c, 'longExposure')
    .header(t('app.positions.longExposure'))
    .shortHeader(t('app.positions.longExposure', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .filter(false)
    .sortable(false);

export const shortExposure: PositionsColumn = (c) =>
  sharedNumericCol(c, 'shortExposure')
    .header(t('app.positions.shortExposure'))
    .shortHeader(t('app.positions.shortExposure', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .filter(false)
    .sortable(false);

export const netExposure: PositionsColumn = (c) =>
  sharedNumericCol(c, 'netExposure')
    .header(t('app.positions.netExposure'))
    .shortHeader(t('app.positions.netExposure', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .filter(false)
    .sortable(false);

export const combinedExposure: PositionsColumn = (c) =>
  sharedNumericCol(c, 'combinedExposure')
    .header(t('app.positions.combinedExposure'))
    .shortHeader(t('app.positions.combinedExposure', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .filter(false)
    .sortable(false);

export const totalPNLToday: PositionsColumn = (c) =>
  sharedNumericCol(c, 'totalPNLToday')
    .header(t('app.positions.totalPNLToday'))
    .shortHeader(t('app.positions.totalPNLToday', { ns: 'short' }))
    .minWidth(120)
    .comparator(sortByAbsoluteValue)
    .cell((c) => c.renderer('PnlCellRenderer'));

export const unrealizedPNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'unrealizedPNL')
    .header(t('app.positions.unrealizedPNL'))
    .shortHeader(t('app.positions.unrealizedPNL', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .cell((c) => c.renderer('PnlCellRenderer'));

export const realizedPNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'realizedPNL')
    .header(t('app.positions.realizedPNL'))
    .shortHeader(t('app.positions.realizedPNL', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .cell((c) => c.renderer('PnlCellRenderer'));

export const realizedPNLToday: PositionsColumn = (c) =>
  sharedNumericCol(c, 'realizedPNLToday')
    .header(t('app.positions.realizedPNLToday'))
    .shortHeader(t('app.positions.realizedPNLToday', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .cell((c) => c.renderer('PnlCellRenderer'));

export const monthToDatePNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'monthToDatePNL')
    .header(t('app.positions.monthToDatePNL'))
    .shortHeader(t('app.positions.monthToDatePNL', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .cell((c) => c.renderer('PnlCellRenderer'));

export const previousMonthPNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'previousMonthPNL')
    .header(t('app.positions.previousMonthPNL'))
    .shortHeader(t('app.positions.previousMonthPNL', { ns: 'short' }))
    .minWidth(120)
    .comparator(sortByAbsoluteValue)
    .cell((c) => c.renderer('PnlCellRenderer'));

export const quarterToDatePNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'quarterToDatePNL')
    .header(t('app.positions.quarterToDatePNL'))
    .shortHeader(t('app.positions.quarterToDatePNL', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .cell((c) => c.renderer('PnlCellRenderer'));

export const previousQuarterPNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'previousQuarterPNL')
    .header(t('app.positions.previousQuarterPNL'))
    .shortHeader(t('app.positions.previousQuarterPNL', { ns: 'short' }))
    .minWidth(120)
    .comparator(sortByAbsoluteValue)
    .cell((c) => c.renderer('PnlCellRenderer'));

export const yearToDatePNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'yearToDatePNL')
    .header(t('app.positions.yearToDatePNL'))
    .shortHeader(t('app.positions.yearToDatePNL', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .cell((c) => c.renderer('PnlCellRenderer'));

export const previousYearPNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'previousYearPNL')
    .header(t('app.positions.previousYearPNL'))
    .shortHeader(t('app.positions.previousYearPNL', { ns: 'short' }))
    .minWidth(120)
    .comparator(sortByAbsoluteValue)
    .cell((c) => c.renderer('PnlCellRenderer'));

export const longPositionCost: PositionsColumn = (c) =>
  sharedNumericCol(c, 'longPositionCost')
    .header(t('app.positions.longPositionCost'))
    .shortHeader(t('app.positions.longPositionCost', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .filter(false)
    .sortable(false);

export const shortPositionCost: PositionsColumn = (c) =>
  sharedNumericCol(c, 'shortPositionCost')
    .header(t('app.positions.shortPositionCost'))
    .shortHeader(t('app.positions.shortPositionCost', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .filter(false)
    .sortable(false);

export const netPositionCost: PositionsColumn = (c) =>
  sharedNumericCol(c, 'netPositionCost')
    .header(t('app.positions.netPositionCost'))
    .shortHeader(t('app.positions.netPositionCost', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .filter(false)
    .sortable(false);

export const combinedPositionCost: PositionsColumn = (c) =>
  sharedNumericCol(c, 'combinedPositionCost')
    .header(t('app.positions.combinedPositionCost'))
    .shortHeader(t('app.positions.combinedPositionCost', { ns: 'short' }))
    .comparator(sortByAbsoluteValue)
    .filter(false)
    .sortable(false);

export const averagePrice: PositionsColumn = (c) =>
  sharedPositionsPrice(c, 'averagePrice')
    .header(t('app.positions.averagePrice'))
    .shortHeader(t('app.positions.averagePrice', { ns: 'short' }));

export const cost: PositionsColumn = (c) =>
  sharedNumericCol(c, 'cost')
    .header(t('app.positions.cost'))
    .shortHeader(t('app.positions.cost', { ns: 'short' }))
    .comparator(sortByAbsoluteValue);

export const trueAveragePrice: PositionsColumn = (c) =>
  sharedPositionsPrice(c, 'trueAveragePrice')
    .header(t('app.positions.trueAveragePrice'))
    .shortHeader(t('app.positions.trueAveragePrice', { ns: 'short' }));

export const valuationPrice: PositionsColumn = (c) =>
  sharedDecimalCol(c, 'valuationPrice')
    .header(t('app.positions.valuationPrice'))
    .shortHeader(t('app.positions.valuationPrice', { ns: 'short' }));

export const positionValuationStrategy: PositionsColumn = (c) =>
  c
    .field('positionValuationStrategy')
    .header(t('app.positions.positionValuationStrategy'))
    .shortHeader(t('app.positions.positionValuationStrategy', { ns: 'short' }))
    .width(150)
    .minWidth(110);

export const lastMarkedDateTime: PositionsColumn = (c) =>
  sharedDateTimeCol(c, 'lastMarkedDateTime')
    .header(t('app.positions.lastMarkedDateTime'))
    .shortHeader(t('app.positions.lastMarkedDateTime', { ns: 'short' }));

export const manualMarkEndDateTime: PositionsColumn = (c) =>
  sharedDateTimeCol(c, 'manualMark.endDateTime')
    .header(t('app.positions.manualMarkEndDateTime'))
    .shortHeader(t('app.positions.manualMarkEndDateTime', { ns: 'short' }));

export const positionExposure: PositionsColumn = (c) =>
  sharedNumericCol(c, 'positionExposure')
    .header(t('app.positions.positionExposure'))
    .shortHeader(t('app.positions.positionExposure', { ns: 'short' }))
    .comparator(sortByAbsoluteValue);

export const exposure: PositionsColumn = (c) =>
  sharedNumericCol(c, 'exposure')
    .header(t('app.positions.exposure'))
    .shortHeader(t('app.positions.exposure', { ns: 'short' }))
    .comparator(sortByAbsoluteValue);

export const tradedToday: PositionsColumn = (c) =>
  sharedNumericCol(c, 'tradedToday')
    .header(t('app.positions.tradedToday'))
    .shortHeader(t('app.positions.tradedToday', { ns: 'short' }))
    .hide(true);

export const accountAccumulationFxRate: PositionsColumn = (c) =>
  sharedDecimalCol(c, 'accountAccumulationFxRate')
    .header(t('app.positions.accountAccumulationFxRate'))
    .shortHeader(t('app.positions.accountAccumulationFxRate', { ns: 'short' }));

export const estimatedCommission: PositionsColumn = (c) =>
  sharedPositionsNumeric(c, 'estimatedCommission')
    .header(t('app.positions.estimatedCommission'))
    .shortHeader(t('app.positions.estimatedCommission', { ns: 'short' }))
    .hide(true);
