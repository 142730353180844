import { Lifecycle, scoped, inject } from 'tsyringe';
import type { EventHandler, EventSource, GridEvent } from '@oms/frontend-vgrid';
import { Logger, Result } from '@oms/shared/util';
import { InstrumentFollowSubject } from './instrument-follow.subject';
import type { InstrumentIdResolver, InstrumentFollowSourceType } from './instrument-follow.contracts';
import type { AnyRecord } from '@oms/frontend-foundation';
import type { Paths } from '@oms/shared/util-types';

const logger = Logger.named('InstrumentFollowEventHandler');

export const INSTRUMENT_FOLLOW_HANDLER_NAME = 'instrument-follow';

export interface CreateInstrumentSelectionEventHandlerOptions<TData extends AnyRecord> {
  sourceType: InstrumentFollowSourceType;
  colId: Paths<TData>;
  getId: InstrumentIdResolver<TData>;
  getDisplayCode?: InstrumentIdResolver<TData>;
}

/**
 * Creates event handler for instrument follow
 * - Fire-and-forget events sent to `InstrumentFollowSubject`
 * - Used by any component that tracks the currently selected instrument
 */
export const createInstrumentFollowEventHandler = <TData extends AnyRecord>({
  sourceType,
  colId,
  getId,
  getDisplayCode
}: CreateInstrumentSelectionEventHandlerOptions<TData>) => {
  @scoped(Lifecycle.ContainerScoped)
  class InstrumentFollowEventHandler implements EventHandler<TData> {
    public name = `${INSTRUMENT_FOLLOW_HANDLER_NAME}-${sourceType}`;
    public sourceType: InstrumentFollowSourceType;

    // 🏗️ Constructor ---------------------------------------------------- /

    constructor(@inject(InstrumentFollowSubject) protected trackingSubject$: InstrumentFollowSubject) {
      this.sourceType = sourceType;
    }

    // 📢 Public ---------------------------------------------------- /

    public addEvents(eventSource: EventSource<keyof GridEvent, TData>): void {
      eventSource.add('onCellClicked', (event) => {
        const doesMatchColOrField =
          event.colDef.colId === String(colId) || event.colDef.field === String(colId);
        if (!doesMatchColOrField) {
          return;
        }
        this.getId(event.data).mapSync(
          (instrumentId) => {
            const instrumentDisplayCode =
              getDisplayCode && event.data ? getDisplayCode(event.data) || undefined : undefined;

            // Don't send the event on deselect
            if (event.api.getSelectedRows().length) {
              this.trackingSubject$.next({
                sourceId: event.node.id,
                sourceType: this.sourceType,
                instrumentId,
                instrumentDisplayCode
              });
            }
          },
          (reason) => {
            logger.warn(`${reason}:`, event);
          }
        );
      });
    }

    public removeEvents(): void {}

    // 🔒 Protected / Private ---------------------------------------------- /

    protected getId(data?: TData): Result<string, string> {
      if (!data) {
        return Result.failure('Data does not exist for the selected row');
      }
      const instrumentId = getId(data);
      if (instrumentId) {
        return Result.success(instrumentId);
      } else {
        return Result.failure('No instrument ID was found for row the selected row');
      }
    }
  }

  return InstrumentFollowEventHandler;
};
