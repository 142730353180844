import { t } from '@oms/codegen/translations';
import type { UnknownRecord } from '@oms/frontend-foundation';
import type { ColumnBuilder, ColumnBuilderField } from '@oms/frontend-vgrid';
import type { UserFragment } from '@oms/generated/frontend';
import { get } from 'lodash';

export const sharedDefaultCol = <T extends UnknownRecord>(c: ColumnBuilder<T>) =>
  c.colDef({ suppressHeaderFilterButton: true, filter: true, resizable: true, sortable: true });

export const sharedIdCol = <T extends UnknownRecord>(c: ColumnBuilder<T>, fieldPath: ColumnBuilderField<T>) =>
  c.field(fieldPath).header(t('app.common.id')).filter('agTextColumnFilter');

export const sharedTextCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) => c.field(fieldPath).resizable().sortable(true).width(110).filter('agTextColumnFilter');

export const sharedNumericCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) =>
  c
    .field(fieldPath)
    .type('numericColumn')
    .format('number')
    .filter('agNumberColumnFilter')
    .resizable()
    .minWidth(100)
    .width(110)
    .maxWidth(160)
    .sortable();

export const sharedPriceCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) => sharedNumericCol(c, fieldPath).format('price');

export const sharedCurrencyCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) =>
  c
    .field(fieldPath)
    .filter('agTextColumnFilter')
    .header(t('app.common.currency'))
    .shortHeader(t('app.common.currency', { ns: 'short' }))
    .width(110);

export const sharedQuantityCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) =>
  c
    .field(fieldPath)
    .header(t('app.common.quantity'))
    .shortHeader(t('app.common.quantity', { ns: 'short' }))
    .format('quantity')
    .filter('agNumberColumnFilter')
    .width(95)
    .maxWidth(180);

export const sharedDecimalCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) => sharedNumericCol(c, fieldPath).format('decimal');

export const sharedDateTimeCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) => c.field(fieldPath).width(180).format('datetime').filter('agDateColumnFilter');

export const sharedDateCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) => c.field(fieldPath).width(110).filter('agDateColumnFilter').format('date');

export const sharedTimestampCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>
) => c.field(fieldPath).width(200).minWidth(90).filter('agDateColumnFilter').format('time12');

export const sharedUserCol = <T extends UnknownRecord>(
  c: ColumnBuilder<T>,
  fieldPath: ColumnBuilderField<T>,
  formatType: 'name' | 'email' = 'name'
) => {
  let cb: ColumnBuilder<T>;

  switch (formatType) {
    case 'email':
      cb = sharedTextCol(c, `${String(fieldPath)}.email`);
      break;
    case 'name':
      cb = sharedTextCol(c, fieldPath).cell((c) =>
        c.valueFormatter<string>(({ data }) => {
          const user = get(data, fieldPath, {}) as UserFragment;

          if (!!user?.firstName && !!user.lastName) {
            return `${user.firstName} ${user.lastName}`;
          }

          if (!!user?.username) {
            return user?.username || '';
          }

          return '';
        })
      );
      break;
  }

  return cb.width(180).filter(false).sortable(false);
};
