import { withUpdatedTradingOrder } from '@app/data-access/hoc/with-updated-trading-order';
import type { UpdatedTradingOrder } from '@app/data-access/hoc/with-updated-trading-order';
import { paneStyle as sx } from '@app/widgets/common/styles/trading.styles';
import { TradingOrderSummary } from './trading-order-view.trading-order-summary.widget';
import { TableServerJoinedTradingOrder } from '@oms/generated/frontend';
import ViewTabsList from '@app/widgets/common/components/view.tabs-list';

const getTabsListProps = (tradingOrder: TableServerJoinedTradingOrder) => [
  {
    name: 'Order Summary',
    value: 'order-summary',
    tabContent: <TradingOrderSummary tradingOrder={tradingOrder} />
  },
  {
    name: 'Charges',
    value: 'charges',
    tabContent: 'Charges will be here'
  },
  {
    name: 'Cost Plus',
    value: 'cost-plus',
    tabContent: 'Cost Plus will be here'
  }
];
export const TradingOrderViewTabbedSummariesWidget = ({ data: tradingOrder }: UpdatedTradingOrder) => {
  return <ViewTabsList defaultValue="order-summary" tabs={getTabsListProps(tradingOrder)} />;
};

export default withUpdatedTradingOrder(TradingOrderViewTabbedSummariesWidget, { sx });
