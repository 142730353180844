import { inject, singleton } from 'tsyringe';
import { Logger } from '@oms/shared/util';
import { testScoped } from '@app/workspace.registry';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import {
  DismissNotificationDocument,
  DismissNotificationMutation,
  DismissNotificationMutationVariables
} from '@oms/generated/frontend';

@testScoped
@singleton()
export class NotificationsService {
  protected name: string = 'NotificationsService';
  protected logger: Logger;

  constructor(
    @inject(GQLResponse) private gqlResponse: GQLResponse,
    @inject(AuthService) protected authService: AuthService
  ) {
    this.logger = Logger.named(this.name);
  }

  public dismissNotification(notificationId: string) {
    const mutation = this.gqlResponse.wrapMutate<
      DismissNotificationMutation,
      DismissNotificationMutationVariables
    >({
      mutation: DismissNotificationDocument,
      variables: {
        notificationInput: {
          notificationId: notificationId
        }
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }
}
