import { openApplySettlementFxRate } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import { FormValidationFeedbackLevel, ValstroEntitlements } from '@oms/generated/frontend';
import type { ExecutionInfoFragment } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

function isVisible(rowData: ExecutionInfoFragment[]): boolean {
  const isOnlyOneRowSelected = rowData.length === 1;
  const data = rowData[0];
  return isOnlyOneRowSelected && !!data && !!data.id;
}

export const applySettlementFXRateOnChange = async (
  ctx: ActionContext<ExecutionInfoFragment, ActionComponentConfig<ExecutionInfoFragment>>
) => {
  const {
    lifecycle,
    data: [execution]
  } = ctx;
  const orderId = execution?.investorOrderId;
  const executionId = execution?.id;
  const isDisabled = !isVisible(ctx.data);

  ctx.notify({ isDisabled });

  if (lifecycle === 'change') {
    if (isDisabled) {
      return;
    }

    // TODO When infrastructure is implemented to set initialFeedback on the
    // form builder, move this logic there on the input callback
    const initialFeedback = execution.settleFxRate
      ? [
          {
            code: 'PREV_OVERWRITE',
            level: FormValidationFeedbackLevel.Warning,
            message: t('app.orders.settlementFXRateDialog.overwrittenWarning')
          }
        ]
      : undefined;

    await openApplySettlementFxRate(PROCESS_ID.LEADER, {
      title: `${t('app.orders.settlementFXRateDialog.settlementFXRateTitle')}`,
      form: {
        formId: `apply-settlement-fx-rate-${executionId}`,
        input: {
          orderId,
          executionId
        },
        initialFeedback
      }
    });
  }
};

export const applySettlementFXRateAction: ActionDefFactory<ExecutionInfoFragment> = (builder) =>
  builder
    .name('apply_settlement_fx_rate')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('apply_settlement_fx_rate_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: 'Apply FX rate'
        })
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.ApplyFxRate]);
    })
    .customMenu((m) =>
      m
        .name('Apply FX rate')
        .visible(({ rowData }) => isVisible(rowData))
        .tabName(t('app.common.grids.contextMenuTabs.action'))
    )
    .onChange<ActionComponentConfig<ExecutionInfoFragment>>(applySettlementFXRateOnChange);
