import { Operation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { Scope } from '@sentry/react';
import { OperationDefinitionNode } from 'graphql';

const sentryGqlOpNameTag = 'graphql.operation.name';
const sentryGqlOpTypeTag = 'graphql.operation.type';
const sentryGraphqlContextName = 'Graphql Info';

export type GraphQLContextForSentry = {
  operation: Operation;
  scope: Scope;
  context?: Record<string, any>;
};

export const graphqlContextForSentry = ({ operation, scope, context }: GraphQLContextForSentry) => {
  const definition = getMainDefinition(operation.query);
  const queryOperationType = (definition as OperationDefinitionNode).operation;

  const queryName = `graphql.${queryOperationType}.${operation.operationName}`;
  const queryContext = {
    [sentryGqlOpNameTag]: operation.operationName,
    [sentryGqlOpTypeTag]: queryOperationType
  };

  scope.setContext(sentryGraphqlContextName, { ...queryContext, ...(context || {}) });
  scope.setTags(queryContext);

  return {
    queryContext,
    queryName,
    queryOperationType
  };
};
