import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { TradingOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import { openTradingOrderModify } from '@app/common/types/orders/trading-order/open.modify.trading-order';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { t } from '@oms/codegen/translations';

type ModifyTradingOrderActionArgs = { id: string; instrumentDisplayCode: string; status: string };

function isVisible(data: ModifyTradingOrderActionArgs[]) {
  return data.length === 1 && !!data[0] && data[0].status === TradingOrderStatus.Active;
}

export const modifyTradingOrderOnChange = <T extends ModifyTradingOrderActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data, workspace } = ctx;
  const selectedRow = data[0];
  const id = selectedRow?.id;
  const symbol = selectedRow?.instrumentDisplayCode;

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change' && id) {
    openTradingOrderModify(workspace, id, symbol);
  }
};

export const modifyTradingOrderAction =
  <T extends ModifyTradingOrderActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('modify_trading_order')
      .toolbar((t) =>
        t
          .component('action-button')
          .id('modify_trading_order_button')
          .location('HorizontalToolbarRight')
          .props({
            isDisabled: true,
            content: 'Modify'
          })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .customMenu((m) =>
        m
          .name('Modify')
          .visible(({ rowData }) => isVisible(rowData))
          .tabName(t('app.common.grids.contextMenuTabs.action'))
          .priority(10)
      )
      .onChange<ActionComponentConfig<T>>(modifyTradingOrderOnChange);
