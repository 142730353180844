import { ErrorLink, ErrorResponse } from '@apollo/client/link/error';
import { Logger } from '@oms/shared/util';
import * as Sentry from '@sentry/react';
import { graphqlContextForSentry } from './apollo-client.sentry.context';

const l = Logger.create({
  name: 'apollo.client.err.handler'
});

export const apolloClientErrorHandler: ErrorLink.ErrorHandler = ({
  graphQLErrors,
  networkError,
  response,
  operation
}: ErrorResponse) => {
  const hasGraphQLErrors = graphQLErrors && graphQLErrors.length;
  const hasNetworkError = !!networkError;

  l.error(`Graphql Error for ${operation.operationName}`, {
    response,
    graphQLErrors,
    networkError
  });

  Sentry.withScope((scope) => {
    const { queryName, queryOperationType } = graphqlContextForSentry({
      context: response?.data ? { 'graphql.response': response.data } : undefined,
      scope,
      operation
    });

    if (hasGraphQLErrors) {
      graphQLErrors.forEach((err) => {
        Sentry.captureException(err, (errScope) => {
          errScope.setTransactionName(`Graphql Error for ${queryOperationType} ${queryName}`);
          errScope.setTag('graphql.err.type', 'graphql');
          return errScope;
        });
      });
    }

    if (hasNetworkError) {
      Sentry.captureException(networkError, (networkErr) => {
        networkErr.setTransactionName(`Graphql Network Error for ${queryOperationType} ${queryName}`);
        networkErr.setTag('graphql.err.type', 'network');
        return networkErr;
      });
    }
  });
};
