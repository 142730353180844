import { Switch as SwitchComp } from '@oms/shared-frontend/ui-design-system';
import { type ChangeEventHandler, type FC, useCallback } from 'react';
import { useActionProps } from '../../../hooks/action.events.hook';
import type { ActionComponentProps } from '../../../models/actions.model';

export const Switch: FC<ActionComponentProps<any>> = (props) => {
  const actionProps = useActionProps<any, ActionComponentProps<any>>(props);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      actionProps.onChange({
        state: actionProps,
        actionId: actionProps.id,
        lifecycle: 'change',
        value: e.target.value
      });
    },
    [actionProps]
  );

  if (actionProps.isVisible === false) {
    return null;
  }

  return (
    <SwitchComp
      id={actionProps.id}
      placeholder={actionProps.placeholder}
      onChange={onChange}
      isDefaultChecked={!!actionProps.value}
    >
      {props.content}
    </SwitchComp>
  );
};
