import { type ComboBoxItemUnion } from '../../combo-box/combo-box.types';
import { omit, uniqBy } from 'lodash';
import debounce from 'lodash/debounce';
import { BehaviorSubject } from 'rxjs';
import { isPromiseLike } from '@oms/shared/util';
import type {
  ContextMenuItemUnion,
  ContextMenuState,
  IContextMenu,
  MouseEventPos,
  MousePos,
  SimpleContextMenuItem,
  TabNameOrder
} from './in-window.context-menu.model';

const DEFAULT_ACTIONS_TAB_NAME = 'All';

export class ContextMenuService implements IContextMenu {
  private MAX_NUMBER_OF_PRIMARY_ITEMS = 2;
  private allItems: SimpleContextMenuItem[] = [];
  private _tabNameOrder = new Map<string, number>();
  private _$ = new BehaviorSubject<ContextMenuState>({
    isOpen: false,
    primary: [],
    secondary: [],
    comboxItems: [],
    mousePos: { x: 0, y: 0 }
  });

  constructor() {
    this._tabNameOrder.set(DEFAULT_ACTIONS_TAB_NAME, 0);
  }

  public get $() {
    return this._$.asObservable();
  }

  public getAll() {
    return this.allItems;
  }

  public set$(state: ContextMenuState) {
    this._$.next(state);
  }

  public open(mousePos: MouseEventPos | MousePos, context?: SimpleContextMenuItem[]) {
    this._debounceOpen(mousePos, context);
  }

  private _open = (mousePos: MouseEventPos | MousePos, context?: SimpleContextMenuItem[]) => {
    const mouse = 'clientX' in mousePos ? { x: mousePos.clientX, y: mousePos.clientY } : mousePos;
    this._$.next({ ...this._$.value, mousePos: mouse });
    if (context) {
      this.setContext(context);
    }
    this.setOpen(true);
  };

  private _debounceOpen = debounce(this._open, 5);

  public setOpen(isOpen: boolean) {
    this._$.next({ ...this._$.value, isOpen });
  }

  public add(context: SimpleContextMenuItem | SimpleContextMenuItem[]) {
    const items = Array.isArray(context) ? context : [context];
    this.setContext(uniqBy([...this.allItems, ...items], 'id'));
  }

  public remove(context: string | string[]) {
    const ids = Array.isArray(context) ? context : [context];
    this.setContext(this.allItems.filter((item) => !ids.includes(item.id)));
  }

  public update(id: string, context: Partial<Omit<SimpleContextMenuItem, 'id'>>) {
    const index = this.allItems.findIndex((item) => item.id === id);
    if (index === -1) {
      return;
    }

    const item = this.allItems[index];
    this.allItems[index] = { ...item, ...context };
    this.setContext(this.allItems);
  }

  public upsert(context: SimpleContextMenuItem | SimpleContextMenuItem[]) {
    const items = Array.isArray(context) ? context : [context];
    this.add(items);
    for (const item of items) {
      this.update(item.id, item);
    }
  }

  public clear() {
    this.setContext([]);
  }

  public find(id: string) {
    return this.allItems.find((item) => item.id === id);
  }

  public run(id: string | string[]) {
    const ids = Array.isArray(id) ? id : [id];
    for (const id of ids) {
      const item = this.find(id);
      if (item) {
        const result = item.onClick();
        if (isPromiseLike(result)) {
          result.catch(console.error);
        }
      }
    }
  }

  public setContext(items: SimpleContextMenuItem[]) {
    const primaryAll = items
      .filter((item) => item.isPrimary && item.isVisible !== false && item.isDisabled !== true)
      .sort(this.sortItems);
    const primary = primaryAll.slice(0, this.MAX_NUMBER_OF_PRIMARY_ITEMS);

    const secondaryItems = [...items.filter((item) => item.isVisible !== false)];

    let tabs = secondaryItems.sort(this.sortItems).reduce(
      (acc, item) => {
        if (item.tabName) {
          if (!acc[item.tabName]) {
            acc[item.tabName] = [];
          }
          acc[item.tabName].push(item);
        } else {
          acc[DEFAULT_ACTIONS_TAB_NAME] = acc[DEFAULT_ACTIONS_TAB_NAME] || [];
          acc[DEFAULT_ACTIONS_TAB_NAME].push(item);
        }
        return acc;
      },
      {} as Record<string, SimpleContextMenuItem[]>
    );

    // If tab is only one, then it should be set to '' so it doesn't show
    if (Object.keys(tabs).length === 1 && !!tabs[DEFAULT_ACTIONS_TAB_NAME]) {
      tabs = { '': tabs[DEFAULT_ACTIONS_TAB_NAME] };
    }

    const sortedTabs = Object.entries(tabs).sort(([a], [b]) => {
      const aOrder = this._tabNameOrder.get(a) || 0;
      const bOrder = this._tabNameOrder.get(b) || 0;
      return aOrder - bOrder;
    });

    const secondary: ContextMenuItemUnion[] = sortedTabs.flatMap(([tabName, items]) => {
      const sortedItems: ContextMenuItemUnion[] = items.sort(this.sortItems).map((item) => ({
        ...item,
        type: 'item',
        label: item.label,
        id: item.id,
        onClick: item.onClick,
        iconId: item.isChecked ? 'check' : undefined,
        isDisabled: item.isDisabled
      }));

      if (tabName === '') {
        return sortedItems;
      }

      const item: ContextMenuItemUnion = {
        type: 'tab',
        label: tabName,
        items: sortedItems
      };

      return [item];
    });

    this.allItems = items;
    this._$.next({
      ...this._$.value,
      primary,
      secondary,
      comboxItems: this._sanitize(secondary)
    });
  }

  public setTabNameOrder(tabName: TabNameOrder | TabNameOrder[]) {
    this._tabNameOrder.clear();
    this.upsertTabNameOrder(tabName);
  }

  public upsertTabNameOrder(tabName: TabNameOrder | TabNameOrder[]) {
    const tabNames = Array.isArray(tabName) ? tabName : [tabName];
    tabNames.forEach(({ tabName, order }) => {
      this._tabNameOrder.set(tabName, order);
    });
  }

  private sortItems(a: SimpleContextMenuItem, b: SimpleContextMenuItem) {
    const aOrder = a.order || 0;
    const bOrder = b.order || 0;
    return aOrder - bOrder;
  }

  public getContext() {
    return this._$.getValue();
  }

  private _sanitize(options: ContextMenuItemUnion[]): ComboBoxItemUnion[] {
    return options.map((option) => {
      if (option.type === 'item') {
        return {
          ...omit(option, 'onClick'),
          value: option.id
        };
      }

      if (option.type === 'tab') {
        return {
          ...omit(option, 'items'),
          items: this._sanitize(option.items)
        };
      }

      return option;
    });
  }
}
