/**
 * Types/enums/constants/etc. for the notifications domain that are shared between the client and backend service.
 */
export namespace SharedNotification {
  /**
   * Represents the priority of a notification.
   */
  export enum NotificationPriority {
    Top,
    High,
    Normal
  }

  /**
   * Feedback from the target object's operation
   */
  export interface TargetObjectFeedback {
    errorCode: string;
    message: string;
  }

  /**
   * Type of alert. Tells the UI which tabs to display the Notification in.
   * <a href='https://www.notion.so/valstro/TKT-10897-Story-BE-Translate-viz-reason-to-notification-alert-type-123b66e5d86680f19da5ed87d40b6937'>TKT-10897</a>.
   */
  export enum PrimaryAlertType {
    MyAlert = 'MY_ALERT',
    TeamAlert = 'TEAM_ALERT',
    AllAlert = 'ALL_ALERT'
  }

  /**
   * Well-known set of default notification names.
   *
   */
  export enum DomainDefaultNotificationName {
    IO_NEW = 'IO_NEW',
    IO_MODIFY = 'IO_MODIFY',
    IO_FAILED = 'IO_FAILED',
    IO_MODIFY_FAILED = 'IO_MODIFY_FAILED',
    TRADE_FAILED = 'TRADE_FAILED',
    TRADE_MODIFY_FAILED = 'TRADE_MODIFY_FAILED',
    IO_CANCEL = 'IO_CANCEL',
    TO_CREATE_REJECTED = 'TO_CREATE_REJECTED',
    TO_CANCEL_REJECTED = 'TO_CANCEL_REJECTED',
    TO_MODIFY_REJECTED = 'TO_MODIFY_REJECTED',
    TO_UNSOLICITED_CANCEL = 'TO_UNSOLICITED_CANCEL'
  }

  /**
   * The durability type of the notification (e.g. "how long it stays around")
   */
  export enum NotificationTypeEnum {
    DISMISSABLE = 'Dismissable',
    PERSIST = 'Persist'
  }
}
