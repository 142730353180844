import { ROUTE_ORDER_ACTION_NAME } from './route-order.action.types';
import type {
  ActionCommandConfig,
  ActionCommandContracts,
  ActionCommandType
} from '@app/actions/commands/command.registry.types';
import type { RouteOrderFormValues as FormValues } from '../../route-order.form-contract';
import type { RouteOrderFormInput as FormInput } from '../../route-order.form-common';
import { openRouteInvestorOrder } from '../../route-order.form-open';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { ActionDefFactory, ActionContext } from '@oms/frontend-vgrid';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { t } from '@oms/codegen/translations';
import routeOrderBuilder from '../../route-order.form-builder';
import { runConfigButton } from '@app/actions/util/run-config-button.util';

// Types --------------------------------------------------------------------- /

export type CreateRouteOrderActionType = ActionCommandType;
export type RouteOrderActionArgs = {
  id: string;
  status: string;
  openQuantity: number;
  instrumentDisplayCode: string;
  instrument: string;
};

// Action --------------------------------------------------------------------- /

export const createRouteOrderAction =
  <TData extends RouteOrderActionArgs>(type: CreateRouteOrderActionType): ActionDefFactory<TData> =>
  (builder) =>
    builder
      .name(type === 'configurable' ? ROUTE_ORDER_ACTION_NAME : 'route_order_static')
      .toolbar(
        type === 'configurable'
          ? (toolbar) =>
              toolbar
                .location('HorizontalToolbarRight')
                .component('action-button')
                .id('left_route_order_button')
                .props({ variant: 'primary', content: t('app.commands.route.button'), isDisabled: true })
          : null
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);
        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .customMenu(
        type === 'context-menu'
          ? (m) =>
              m
                .name(t('app.commands.route.button'))
                .tabName(t('app.common.grids.contextMenuTabs.trade'))
                .priority(30)
                .visible(({ rowData }) => isActive(rowData))
                .primary()
          : null
      )
      .lifecycles('change', 'init', 'onSelectionChanged', 'onRowDataUpdated')
      .onChange<ActionCommandConfig<ActionCommandContracts['route_order']>>(routeOrderActionOnChange);

// Util --------------------------------------------------------------------- /

/**
 * Re-useable function to handle the onChange lifecycle of the route order action
 * - This function will open the route order form
 * - This function will also handle the initial values of the form (if config is provided)
 *
 * @param ctx - The action event
 * @returns Change function
 */
export async function routeOrderActionOnChange<T extends RouteOrderActionArgs>(ctx: ActionContext<T>) {
  const { lifecycle, data, notify, workspace } = ctx;
  const [selectedRow] = data;
  const { id, instrumentDisplayCode } = selectedRow || {};

  // -------- Handle button state --------

  const isDisabled = !isActive(data);

  notify({ isDisabled });

  if (lifecycle !== 'change' || isDisabled) {
    return;
  }

  // -------- Handle clicking the button --------

  notify({ isLoading: true });

  await runConfigButton<T, FormInput, FormValues>({
    ctx,
    name: t('app.commands.route.button'),
    formBuilder: routeOrderBuilder,
    input: { mode: { type: 'route', investorOrderId: id || '' } },
    fixedFormValueKeys: ['instrument', 'sideType', 'settlementType', 'settlementDate'],
    handleError: true,
    onOpenForm: ({ initialValues, initialFeedback }) => {
      openRouteInvestorOrder(
        workspace,
        { id, symbol: instrumentDisplayCode },
        { form: { initialValues, initialFeedback } }
      );
    }
  });

  notify({ isLoading: false });
}

/**
 * Re-useable function to determine if the button should be active
 *
 * @param rowData - The data from the grid
 * @returns Whether the button should be active
 */
export function isActive(rowData?: RouteOrderActionArgs[]) {
  return (
    rowData !== undefined &&
    rowData.length === 1 &&
    !!rowData[0] &&
    rowData[0]?.status === InvestorOrderStatus.Active &&
    rowData[0]?.openQuantity !== null &&
    rowData[0]?.openQuantity !== undefined &&
    rowData[0]?.openQuantity > 0
  );
}
