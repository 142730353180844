import pino, { LevelWithSilent, LoggerOptions, WriteFn, levels } from 'pino';

const writeFn: WriteFn = (log: Record<string, any>) => {
  const rawLevel = log['level'];
  const level: LevelWithSilent =
    rawLevel && typeof rawLevel === 'string'
      ? (rawLevel.toLowerCase() as LevelWithSilent)
      : rawLevel && typeof rawLevel === 'number'
        ? (levels.labels[rawLevel]?.toLowerCase() as LevelWithSilent) || 'info'
        : 'info';

  level !== 'silent' && console[level === 'fatal' ? 'error' : level](log);
};

export const DEFAULT_LOGGER_OPTS: LoggerOptions = {
  level: 'error',
  browser: {
    asObject: true,
    serialize: true,
    write: (log) => writeFn(log)
  }
};

export const createPinoLogger = (opts: LoggerOptions = DEFAULT_LOGGER_OPTS) => {
  return pino(opts, {
    // this is for tests... pino does some magic in figuring out whether its in a node process or not, and it doesnt use the proper console. methods in node
    write: (msgStr: string) => {
      writeFn(JSON.parse(msgStr) as Record<string, any>);
    }
  });
};
