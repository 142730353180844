import type { ColDef, GridApi } from '@ag-grid-community/core';
import { exists, t } from 'i18next';
import { ServerSideDatasource } from '../models/datasource.model';
import { ActionComponentConfig, ActionContext, ActionDefFactory } from '../models/actions.model';

type GroupByRow = {
  id: string;
};

type GroupByActionArgs<T extends GroupByRow> = {
  aggregateDatasource: ServerSideDatasource<T>;
  detailDatasource: ServerSideDatasource<T>;
  groupFieldName: keyof T;
  label: string;
};

export const updateColumnDefsForGrouping = <T extends GroupByRow>(
  colDefs: ColDef[],
  groupFieldName: keyof T,
  isGrouped: boolean
): ColDef[] =>
  colDefs.map((col) =>
    col.colId === groupFieldName
      ? {
          ...col,
          enableRowGroup: true,
          rowGroup: isGrouped,
          hide: isGrouped,
          id: `group-${col.colId}`,
          filter: !isGrouped
        }
      : col.filter !== !isGrouped
        ? { ...col, filter: !isGrouped }
        : col
  );

export const translateHeader = <T extends GroupByRow>(groupFieldName: keyof T) => {
  const path = `app.tableServer.common.${String(groupFieldName)}`;

  return exists(path, { ns: 'long' }) ? t(path, { ns: 'long' }) : String(groupFieldName);
};

export const configureAutoGroupColumnDef = <T extends GroupByRow>(
  groupFieldName: keyof T,
  colDefs: ColDef[]
): ColDef => {
  const colDef = colDefs.find((def) => def.colId === groupFieldName);
  return {
    flex: 1,
    minWidth: 120,
    field: groupFieldName as string,
    headerName: translateHeader(groupFieldName),
    cellRendererParams: {
      suppressCount: false,
      suppressPadding: true
    },
    filter: false,
    valueFormatter: colDef?.valueFormatter
  };
};

const handleInitLifecycle = <T extends GroupByRow>(
  api: GridApi,
  colDefs: ColDef[],
  groupFieldName: keyof T,
  aggregateDatasource: ServerSideDatasource<T>,
  notify: (value: { value: boolean }) => void
) => {
  const isGrouped = !!colDefs.find((col) => col.rowGroup);
  if (isGrouped) {
    api.setGridOption('serverSideDatasource', aggregateDatasource);
    notify({ value: true });
  }

  api.updateGridOptions({
    autoGroupColumnDef: configureAutoGroupColumnDef(groupFieldName, colDefs),
    columnDefs: updateColumnDefsForGrouping(colDefs, groupFieldName, isGrouped)
  });
};

const handleChangeLifecycle = <T extends GroupByRow>(
  api: GridApi,
  colDefs: ColDef[],
  groupFieldName: keyof T,
  aggregateDatasource: ServerSideDatasource<T>,
  detailDatasource: ServerSideDatasource<T>
) => {
  const isGrouped = !colDefs.some((col) => col.rowGroup);
  api.updateGridOptions({
    columnDefs: updateColumnDefsForGrouping(colDefs, groupFieldName, isGrouped)
  });

  const datasource = isGrouped ? aggregateDatasource : detailDatasource;
  api.setGridOption('serverSideDatasource', datasource);
};

export const handleGroupByAction =
  <T extends GroupByRow>({ aggregateDatasource, detailDatasource, groupFieldName }: GroupByActionArgs<T>) =>
  <T extends GroupByRow>(ctx: ActionContext<T, ActionComponentConfig<T>>) => {
    const { lifecycle, api, notify } = ctx;
    const colDefs = api.getColumnDefs() as ColDef<T>[];

    if (lifecycle === 'init') {
      handleInitLifecycle(api, colDefs, groupFieldName, aggregateDatasource, notify);
      return;
    }

    if (lifecycle === 'change') {
      handleChangeLifecycle(api, colDefs, groupFieldName, aggregateDatasource, detailDatasource);
    }
  };

export const groupByAction =
  <T extends GroupByRow>(args: GroupByActionArgs<T>): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('group_by')
      .toolbar((t) =>
        t.component('action-switch').id('group_by_button').location('HorizontalToolbarLeft').props({
          content: args.label
        })
      )
      .customMenu((m) => m.name(args.label))
      .onChange<ActionComponentConfig<T>>(handleGroupByAction(args));
