import { useCallback, useRef, useState, useEffect } from 'react';
import type { FC } from 'react';
import { useFieldApi, FieldWrapper, useEnhancedFormApi } from '@oms/frontend-foundation';
import type { FieldProps, ICommonField } from '@oms/frontend-foundation';
import { FieldArray } from '@data-driven-forms/react-form-renderer';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import type { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Box, HStack } from '@oms/shared-frontend/ui-design-system';
import { Button } from '@oms/shared-frontend/ui-design-system';
import { CheckIcon, CopyIcon, PlusIcon } from '@radix-ui/react-icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { dragModel, separator } from '../utils/format.actions';
import {
  ACTION_COMMANDS_TO_LABELS,
  getCommandFromLabel,
  buttonLayoutActionSchema
} from '../utils/button.action.schema';
import { onPreventDefault } from '../utils/on.prevent.default';
import { DragItemTypes } from '../types';
import type {
  DraggableItem,
  DraggableAction,
  DraggableItemTypes,
  ActionDndContext,
  LayoutAction
} from '../types';
import type {
  ActionButtonLayoutFormInput,
  ActionButtonLayoutFormOutput
} from './action-button-layout.contracts';
import { useOpenActionButton } from '@app/generated/sdk';
import type { FORM_MAP } from '@app/generated/mappers';
import type { ActionButtonFormInput, ActionButtonFormOutput } from '../action-button/action-button.contracts';
import type { Subscription } from 'rxjs';
import type { FORM_COMPONENT_TYPE } from '@app/forms/form-builder/common/form.contracts';
import { ActionButtonGroup } from '../action-button-group/action-button-group';
import { DropZone } from '../drop-zone/drop-zone';
import { ActionButton } from '../action-button/action.button';
import { Separator } from '../separator/separator';
import type { Form } from '@app/common/registry/form.open';
import type { Action } from '@app/actions/types/action.types';
import { useCurrentWidgetActorProps } from '@valstro/workspace-react';
import { RemoteFormComponentProps } from '@app/widgets/system/remote-form/remote-form.widget.config';
import { useCopyAllActions, usePasteActions } from './action-button-layout.copy-field.hook';

export type ActionButtonLayoutValue = {
  actions?: LayoutAction[];
} & Pick<ActionButtonLayoutFormOutput['layout'], 'requiredFields'>;

export interface IActionButtonLayoutField<TValidator = Validator>
  extends ICommonField<
    typeof FORM_COMPONENT_TYPE.ACTION_BUTTON_LAYOUT,
    ActionButtonLayoutValue,
    TValidator
  > {}
type DragHandler = (ctx: ActionDndContext) => void;
type DraggableItemComponentProps = {
  name?: string;
  renderForm: (schema: any) => any;
  action: DraggableItem;
  idx: number;
  pathPrefix: string;
  drag: DragHandler;
  drop: DragHandler;
  dropBefore: DragHandler;
  dropAfter: DragHandler;
  onEdit: (action: ActionButtonFormInput) => void;
  onDelete: (objectPath: string, idx: number) => void;
  cosi: FieldArrayRenderProps<Action, HTMLElement>;
  isLast: boolean;
};

export const DraggableItemComponent = ({
  name,
  renderForm,
  action,
  idx,
  pathPrefix,
  drag,
  drop,
  dropBefore,
  dropAfter,
  onEdit,
  onDelete,
  cosi,
  isLast
}: DraggableItemComponentProps) => {
  const currentPath = pathPrefix.length > 0 ? `${pathPrefix}.${idx}` : `${idx}`;

  let type: any;
  if ('isSeparator' in action) {
    type = DragItemTypes.Separator;
  } else if (Array.isArray(action)) {
    type = DragItemTypes.ActionGroup;
  } else {
    type = DragItemTypes.Action;
  }

  const dragCtx: ActionDndContext = { action, type, objectPath: pathPrefix, idx };
  const dndProps = {
    key: currentPath,
    onDelete: () => {
      onDelete(pathPrefix, idx);
    },
    onDrag: () => {
      drag(dragCtx);
    },
    onDrop: () => {
      drop(dragCtx);
    }
  };

  if (type === DragItemTypes.Separator) {
    return (
      <>
        <DropZone
          onDrop={() => {
            dropBefore(dragCtx);
          }}
        />
        <Separator {...dndProps} />
        {isLast && <DropZone onDrop={() => dropAfter(dragCtx)} />}
      </>
    );
  } else if (Array.isArray(action) && type === DragItemTypes.ActionGroup) {
    return (
      <>
        <DropZone
          onDrop={() => {
            dropBefore(dragCtx);
          }}
        />
        <ActionButtonGroup {...dndProps}>
          {action.map((a, i) => (
            <DraggableItemComponent
              {...{
                key: `drag-item-${currentPath}.${i}`,
                renderForm,
                action: a,
                idx: i,
                pathPrefix: currentPath,
                drag,
                drop,
                dropBefore,
                dropAfter,
                onDelete,
                onEdit,
                cosi,
                isLast: i === action.length - 1
              }}
            />
          ))}
        </ActionButtonGroup>
        {isLast && <DropZone onDrop={() => dropAfter(dragCtx)} />}
      </>
    );
  } else {
    return (
      <>
        <DropZone
          onDrop={() => {
            dropBefore(dragCtx);
          }}
        />
        <ActionButton
          {...dndProps}
          action={action as typeof type}
          onEdit={() => {
            onEdit(action as typeof type);
          }}
        >
          {renderForm(buttonLayoutActionSchema(`${name || ''}.actions[${idx || 0}]`, action as typeof type))}
        </ActionButton>
        {isLast && <DropZone onDrop={() => dropAfter(dragCtx)} />}
      </>
    );
  }
};

export const ActionButtonLayoutField: FC<FieldProps<IActionButtonLayoutField>> = (props) => {
  const field = useFieldApi<IActionButtonLayoutField, ActionButtonLayoutValue>(props);
  const {
    input: { value, name }
  } = field;
  const cosiRef = useRef<FieldArrayRenderProps<Action, HTMLElement>>();
  const formApi = useEnhancedFormApi();
  const { renderForm, change } = formApi;
  const draggedAction = useRef<ActionDndContext>();
  const uniqueId = useRef<number>(0);
  const editFormSub = useRef<Subscription>();
  const [editForm, setEditForm] = useState<Form<(typeof FORM_MAP)['ACTION_BUTTON']>>();
  const openActionDialog = useOpenActionButton();
  const { actions = [], requiredFields } = value;
  const [widgetProps] = useCurrentWidgetActorProps<RemoteFormComponentProps>();
  const input = widgetProps.input as ActionButtonLayoutFormInput;

  const newDraggableAction = useCallback(
    (props: Partial<DraggableAction> = {}): DraggableAction => {
      const newAction: DraggableAction = {
        id: `action-${uniqueId.current}`,
        payload: undefined,
        label: '',
        color: 'Blue',
        size: 'S',
        ...requiredFields,
        ...props
      };
      uniqueId.current++;
      return newAction;
    },
    [requiredFields]
  );

  const addDraggableItem = useCallback((itemType: keyof DraggableItemTypes): void => {
    if (!cosiRef.current) return;
    const toArray = dragModel.model;
    if (itemType === 'DraggableAction') {
      toArray.push(newDraggableAction());
    } else if (itemType === 'DraggableGroup') {
      toArray.push([newDraggableAction()]);
    } else if (itemType === 'DraggableSeparator') {
      toArray.push(separator);
    }

    if (!cosiRef.current) return;
    change(cosiRef.current.fields.name, dragModel.output());
  }, []);

  const drag: (ctx: ActionDndContext) => void = useCallback(({ action, type, objectPath, idx }) => {
    draggedAction.current = { action, type, objectPath, idx };
  }, []);

  const drop: (ctx: ActionDndContext) => void = useCallback((dropCtx) => {
    if (!draggedAction.current || !cosiRef.current) return;
    const { type: dropType, objectPath: dropPath, idx: dropIdx } = dropCtx;
    const { type: dragType, objectPath: dragPath, idx: dragIdx } = draggedAction.current;

    switch (dragType) {
      case DragItemTypes.Separator:
        dragModel.moveItemAfterTarget(dragPath, dragIdx, dropPath, dropIdx);
        break;
      case DragItemTypes.Action:
        if (dropType === DragItemTypes.Action) {
          dragModel.swapItems(dragPath, dragIdx, dropPath, dropIdx);
        } else if (dropType === DragItemTypes.ActionGroup) {
          dragModel.moveItem(dragPath, dragIdx, dropPath ? dropPath + '.' + dropIdx : dropIdx + '', 0);
        }
        break;
      case DragItemTypes.ActionGroup:
        if (dropType === DragItemTypes.Action) {
          dragModel.moveGroupAndAppendTarget(dragPath, dragIdx, dropPath, dropIdx);
        } else if (dropType === DragItemTypes.ActionGroup) {
          dragModel.moveItem(dragPath, dragIdx, dropPath, dropIdx);
        }
        break;
    }

    change(cosiRef.current.fields.name, dragModel.output());
  }, []);

  const dropBefore: (ctx: ActionDndContext) => void = useCallback((dropCtx) => {
    if (!draggedAction.current || !cosiRef.current) return;
    const { objectPath: dragPath, idx: dragIdx } = draggedAction.current;
    const { objectPath: dropPath, idx: dropIdx } = dropCtx;
    dragModel.moveItemBeforeTarget(dragPath, dragIdx, dropPath, dropIdx);
    change(cosiRef.current.fields.name, dragModel.output());
  }, []);

  const dropAfter: (ctx: ActionDndContext) => void = useCallback((dropCtx) => {
    if (!draggedAction.current || !cosiRef.current) return;
    const { objectPath: dragPath, idx: dragIdx } = draggedAction.current;
    const { objectPath: dropPath, idx: dropIdx } = dropCtx;
    dragModel.moveItemAfterTarget(dragPath, dragIdx, dropPath, dropIdx);
    change(cosiRef.current.fields.name, dragModel.output());
  }, []);

  const onDelete = useCallback((objectPath: string, idx: number) => {
    if (!cosiRef.current) return;
    dragModel.removeItem(objectPath, idx);
    change(cosiRef.current.fields.name, dragModel.output());
  }, []);

  const onEdit = useCallback(
    async (action: ActionButtonFormInput) => {
      await openActionDialog('currentWindow', {
        form: {
          input: {
            ...action,
            commandId: getCommandFromLabel(action.commandId),
            confirmation:
              action.confirmation !== undefined && action.confirmation !== null
                ? !action.confirmation
                : action.confirmation,
            allowedCommands: requiredFields.allowedCommands || []
          }
        }
      }).then((form: Form<(typeof FORM_MAP)['ACTION_BUTTON']>) => {
        if (editForm) {
          editForm.dispose();
        }
        setEditForm(form);
      });
    },
    [editForm, openActionDialog, requiredFields]
  );

  useEffect(() => {
    if (editFormSub.current) {
      editFormSub.current.unsubscribe();
      editFormSub.current = undefined;
    }

    if (!editForm) return;

    editFormSub.current = editForm.events$.subscribe((e) => {
      switch (e.type) {
        case 'SUBMIT':
          if (cosiRef.current) {
            const idx = cosiRef.current.fields.value.findIndex(
              (a) => (e.payload.formValues as unknown as ActionButtonFormOutput)?.id === a.id
            );

            const labelOrFn = e.payload.formValues.commandId
              ? ACTION_COMMANDS_TO_LABELS[e.payload.formValues.commandId]
              : e.payload.formValues.commandId;

            const commandId = labelOrFn
              ? typeof labelOrFn === 'function'
                ? labelOrFn()
                : labelOrFn
              : e.payload.formValues.commandId;

            cosiRef.current.fields.update(idx === undefined ? -1 : idx, {
              ...(e.payload.formValues as unknown as Action),
              commandId: commandId as Action['commandId'],
              payload: e.payload.formValues?.payload?.payload,
              confirmation: !e.payload.formValues?.confirmation
            });
          }
          break;
        case 'SUBMIT_FINISHED_SUCCESS':
          editForm.dispose();
          setEditForm(undefined);
          break;
      }
    });

    return () => {
      editFormSub.current?.unsubscribe();
    };
  }, [editForm]);

  // Handles copy for ALL actions
  const [onCopy, hasCopied] = useCopyAllActions(formApi);

  // Handles paste for ALL actions OR a single action
  const pasteWrapperRef = usePasteActions(formApi, name, input);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 'full',
        overflow: 'hidden',
        backgroundColor: 'layout.level2',
        justifyContent: 'space-around'
      }}
      ref={pasteWrapperRef}
      tabIndex={0} // Ensure that the field wrapper element is focusable
    >
      <DndProvider backend={HTML5Backend}>
        <FieldWrapper sx={{ overflowY: 'auto', height: 'auto', display: 'flex', flexDirection: 'column' }}>
          <FieldArray key={`${name}.actions`} name={`${name}.actions`}>
            {(cosi: FieldArrayRenderProps<Action, HTMLElement>) => {
              cosiRef.current = cosi;
              dragModel.input(actions);
              return dragModel.model.map((action, idx) => {
                return (
                  <DraggableItemComponent
                    {...{
                      name,
                      key: `drag-item-${idx}`,
                      renderForm,
                      action,
                      idx,
                      pathPrefix: '',
                      drag,
                      drop,
                      dropBefore,
                      dropAfter,
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onEdit,
                      onCopy,
                      onDelete,
                      cosi,
                      dragModel,
                      isLast: idx === dragModel.model.length - 1
                    }}
                  />
                );
              });
            }}
          </FieldArray>
        </FieldWrapper>
      </DndProvider>
      <HStack
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          margin: 2,
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          aria-label="add-action-button"
          leftIcon={<PlusIcon />}
          variant="secondary"
          onClick={(e) => {
            onPreventDefault(e, () => {
              addDraggableItem('DraggableAction');
            });
          }}
        >
          Add action
        </Button>
        {/* TODO: Add this back when we work on split buttons AND fix the current bug that crashes the form. */}
        {/* <Button
          aria-label="add-action-button-group"
          leftIcon={<PlusIcon />}
          variant="secondary"
          onClick={(e) => {
            onPreventDefault(e, () => {
              addDraggableItem('DraggableGroup');
            });
          }}
        >
          Add action group
        </Button> */}
        <Button
          aria-label="add-action-button"
          leftIcon={<PlusIcon />}
          variant="secondary"
          onClick={(e) => {
            onPreventDefault(e, () => {
              addDraggableItem('DraggableSeparator');
            });
          }}
        >
          Add separator
        </Button>
        {dragModel.model.length > 0 && (
          <Button
            leftIcon={hasCopied ? <CheckIcon /> : <CopyIcon />}
            aria-label="copy-all"
            variant="secondary"
            isDisabled={hasCopied}
            onClick={(e) => {
              onPreventDefault(e, () => {
                onCopy();
              });
            }}
          >
            {hasCopied ? 'Copied' : 'Copy all'}
          </Button>
        )}
      </HStack>
    </Box>
  );
};
