import { useRef } from 'react';
import type { FC } from 'react';
import { map, switchMap, tap } from 'rxjs';
import { Logger } from '@oms/shared/util';
import { VGrid, toClientsideGridData, useVGrid } from '@oms/frontend-vgrid';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import { broadcastGridSelection } from '@app/data-access/memory/grid.events';
import useTabSelectionManager from '@app/data-access/memory/grid-tab/use-tab-selection-manager.hook';
import { usePositionsForAccountSubscription } from '@app/data-access/services/trading/positions/util/use-positions-subscription.hook';
import type { PositionsGridType } from '../common/types';
import getColumnLibraryFor from '../common/columns/positions.column-library';
import groupByInstrumentAction from './grid-actions/group-by-instrument-action';
import { usePositionsInstrumentGridServiceRef } from './services/use-positions-instrument-grid-service.hook';

const gridType: PositionsGridType = 'positions-instrument-grid';
const accountGridType: PositionsGridType = 'positions-account-grid';

const logger = Logger.named('PositionsInstrumentGrid');

/** Drilldown grid for positions widget (lower pane) */
export const PositionsInstrumentGrid: FC = () => {
  const gridServiceRef = usePositionsInstrumentGridServiceRef();

  const tabSelectionManager = useTabSelectionManager<PositionRow>(accountGridType);

  const getPositionsForAccount$ = usePositionsForAccountSubscription();

  const dataRef$ = useRef(
    tabSelectionManager.selectedRow$.pipe(
      tap((row) => {
        logger
          .scope('dataRef$')
          .debug(
            `${row?.name ? `Selected ${row.name}` : 'No account selected'} on tab: ${
              tabSelectionManager.selectedTabId
            }`
          );
      }),
      map((row) => row?.id),
      switchMap(getPositionsForAccount$),
      toClientsideGridData(),
      tap((gridData) => {
        if (gridData === null) {
          logger.scope('dataRef$').debug('Loading...');
        } else if (typeof gridData === 'undefined') {
          logger.scope('dataRef$').debug(`🟧 No grid data`);
        }
      })
    )
  );

  const props = useVGrid<PositionRow>(
    gridType,
    (builder) =>
      builder
        .columnLibrary(getColumnLibraryFor(gridType))
        .datasource((d) => d.source(dataRef$.current).rowId(({ data }) => data.id))
        .defaultColumn((c) => c.filter().sortable().resizable())
        .autoGroupColumnDef({
          field: 'account.name',
          headerName: 'Instrument',
          width: 200,
          minWidth: 130
        })
        .onVGridReady(({ gridId, gridApi }) => {
          gridServiceRef.current.init(gridApi, { gridId });
          gridApi.sizeColumnsToFit();
        })
        .rowSelection((rs) => rs.multiple().broadcast(broadcastGridSelection()))
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(groupByInstrumentAction(gridServiceRef.current))
                .action('clear-grid-filter')
                .action('row-selection-count')
            )
            .settings((s) => s.fromFile({}))
        )
        .sideBar(),
    []
  );
  return <VGrid {...props} />;
};

export default PositionsInstrumentGrid;
