import type { GridApi } from '@ag-grid-community/core';
import type { CustomFilterProps, CustomFloatingFilterProps } from '@ag-grid-community/react';
import { FormRenderer, useFormApi } from '@data-driven-forms/react-form-renderer';
import { FORM_COMPONENT_MAPPER, useEnhancedFormApi } from '@oms/frontend-foundation';
import type { ComboBoxItem } from '@oms/frontend-foundation';
import type { FormTemplateRenderProps, Schema } from '@data-driven-forms/react-form-renderer';
import { Box } from '@oms/shared-frontend/ui-design-system';
import { useCallback, useEffect } from 'react';
import { BehaviorSubject, pairwise } from 'rxjs';
import type { AnyRecord } from '@valstro/workspace';
import type { Sprinkles } from '@oms/shared-frontend/ui-design-system';
import type {
  AgGridCustomDateFilter,
  CustomFilterType
} from '@app/data-access/services/system/table-server/filters/ag-grid.filters.schema';
import { useObservableState } from 'observable-hooks';
import omit from 'lodash/omit';

type DateFilterValues = { period?: CustomFilterType; dateFrom?: string; dateTo?: string };

type DateFilterChangePayload = {
  gridId: string;
  columnId: string;
  values: DateFilterValues;
};

export const filterChange$ = new BehaviorSubject<DateFilterChangePayload>({
  gridId: '',
  columnId: '',
  values: {}
});

type CustomFilterComboboxItem = Omit<ComboBoxItem, 'value'> & { value: CustomFilterType };

const options: CustomFilterComboboxItem[] = [
  {
    type: 'item',
    label: '',
    value: 'no-filter',
    id: 'no-filter'
  },
  {
    type: 'item',
    label: 'Today',
    value: 'today',
    id: 'today'
  },
  {
    type: 'item',
    label: 'Yesterday',
    value: 'yesterday',
    id: 'yesterday'
  },
  {
    type: 'item',
    label: 'Last 7 days',
    value: '7-days',
    id: '7-days'
  },
  {
    type: 'item',
    label: 'Last 30 days',
    value: '30-days',
    id: '30-days'
  },
  {
    type: 'item',
    label: 'Last 90 days',
    value: '90-days',
    id: '90-days'
  },
  {
    type: 'item',
    label: 'Date range',
    value: 'range',
    id: 'range'
  }
];

const mainFilterSchema: Schema = {
  fields: [
    {
      name: 'period',
      component: 'select',
      options,
      hideFormControls: true
    },
    {
      name: 'dateFrom',
      component: 'native-date-picker',
      condition: { when: 'period', is: 'range' },
      hideFormControls: true
    },
    {
      name: 'dateTo',
      component: 'native-date-picker',
      condition: { when: 'period', is: 'range' },
      hideFormControls: true
    }
  ]
};

const floatingFilterSchema: Schema = {
  fields: [
    {
      name: 'period',
      component: 'select',
      options,
      hideFormControls: true
    }
  ]
};

type FilterFormTemplateProps = {
  columnId: string;
  api: GridApi;
  onModelChange: (model: AnyRecord) => void;
  showParentFilter?: () => void;
  sx?: Sprinkles;
} & FormTemplateRenderProps;

export const FilterFormTemplate = ({
  columnId,
  onModelChange,
  showParentFilter,
  api,
  schema,
  sx
}: FilterFormTemplateProps) => {
  const { renderForm } = useFormApi();
  const formApi = useEnhancedFormApi<DateFilterValues>();
  const gridId = api.getGridId();
  const changePayload = useObservableState(filterChange$);
  const model = api.getFilterModel();

  useEffect(() => {
    const filterValue = model[columnId] as AgGridCustomDateFilter;

    if (filterValue) {
      // populate filter inputs on initial load
      for (const [key, value] of Object.entries(omit(filterValue, 'filterType'))) {
        formApi.change(key as keyof DateFilterValues, value as string);
      }
    } else {
      // otherwise select blank option
      formApi.change('period', 'no-filter');
    }

    const filterChangedHandler = () => {
      // select blank option when all filters get cleared
      if (!Object.keys(api.getFilterModel()).length) {
        formApi.change('period', 'no-filter');
      }
    };

    api.addEventListener('filterChanged', filterChangedHandler);

    return () => {
      api.removeEventListener('filterChanged', filterChangedHandler);
    };
    /* TODO need to find a better way to fire this hook when the filter model is ready
     rather than adding it as a dependency */
  }, [api, formApi, model]);

  useEffect(() => {
    if (changePayload && changePayload.gridId === gridId && changePayload.columnId === columnId) {
      const values = formApi.getState().values;

      // reset range dateFrom and dateTo
      if (values.period !== 'range') {
        if (values.dateFrom) {
          formApi.change('dateFrom', undefined);
        }
        if (values.dateTo) {
          formApi.change('dateTo', undefined);
        }
      }

      for (const [key, value] of Object.entries(changePayload.values)) {
        if (values[key] !== value) {
          formApi.change(key as keyof DateFilterValues, value);
        }
      }
    }
  }, [changePayload, columnId, gridId, formApi]);

  useEffect(() => {
    const sub = formApi
      .get$({ values: true })
      .pipe(pairwise())
      .subscribe(([{ values: prevValues }, { values }]) => {
        filterChange$.next({
          gridId,
          columnId,
          values
        });

        if (!(values.period === 'range' && (!values.dateFrom || !values.dateTo))) {
          // clear the filter model if blank option is selected
          if (values.period === 'no-filter') {
            const model = api.getFilterModel();
            delete model[columnId];
            api.setFilterModel(model);
          } else {
            onModelChange({
              ...values,
              filterType: 'customDate'
            });
          }
        }

        if (showParentFilter) {
          if (values.period === 'range' && prevValues.period !== 'range') {
            showParentFilter();
          }
        }
      });

    return () => {
      sub.unsubscribe();
    };
  }, [columnId, formApi, onModelChange, showParentFilter]);

  return <Box sx={sx}>{renderForm(schema.fields)}</Box>;
};

export const DateFilter = (params: CustomFilterProps) => {
  const { onModelChange, column, api } = params;
  const columnId = column.getColId();

  const Template = useCallback(
    (props: FormTemplateRenderProps) => {
      return (
        <FilterFormTemplate
          {...props}
          columnId={columnId}
          onModelChange={onModelChange}
          api={api}
          sx={{ padding: 'small' }}
        />
      );
    },
    [columnId, onModelChange, api]
  );

  return (
    <Box style={{ minHeight: '100px' }}>
      <FormRenderer
        schema={mainFilterSchema}
        FormTemplate={Template}
        componentMapper={FORM_COMPONENT_MAPPER}
      />
    </Box>
  );
};

export const FloatingDateFilter = (params: CustomFloatingFilterProps) => {
  const { onModelChange, showParentFilter, column, api } = params;
  const columnId = column.getColId();

  const Template = useCallback(
    (props: FormTemplateRenderProps) => {
      return (
        <FilterFormTemplate
          {...props}
          columnId={columnId}
          onModelChange={onModelChange}
          showParentFilter={showParentFilter}
          api={api}
          sx={{ marginTop: '-0.5' }}
        />
      );
    },
    [columnId, onModelChange, showParentFilter, api]
  );

  return (
    <FormRenderer
      schema={floatingFilterSchema}
      FormTemplate={Template}
      componentMapper={FORM_COMPONENT_MAPPER}
    />
  );
};
