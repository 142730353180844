import type {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import type { TradeRow } from '@oms/generated/frontend';

const tradesMonitorDefaultColumn: EnrichedColumnDef = {
  extends: 'generic.default',
  floatingFilter: true
};

export const tradesMonitorColumns = {
  id: { extends: 'orders.id' },
  instrumentDisplayCode: { extends: 'orders.instrumentDisplayCode' },
  price: { extends: 'orders.price' },
  locate: { extends: 'generic.text' },
  side: {
    extends: 'orders.side'
  },
  quantity: { extends: 'orders.quantity' },
  capacity: { extends: 'orders.orderCapacity' },
  tradeCounterParty: { extends: 'generic.text', width: 168 },
  tradeContraAccount: { extends: 'generic.text', width: 180 },
  lastMarket: { extends: 'generic.text' },
  lastTradeTime: { extends: 'generic.dateTime' },
  createdTime: { extends: 'generic.dateTime' },
  updatedTime: { extends: 'generic.dateTime' },
  owner: { extends: 'orders.owner' },
  rawLiquidityIndicator: { extends: 'generic.text' },
  normalizedLiquidityIndicator: { extends: 'generic.text' },
  status: {
    extends: 'orders.tradeStatus'
  },
  settleType: {
    extends: 'orders.settleType',
    hide: true
  },
  settleDate: {
    extends: 'generic.date',
    minWidth: 160,
    hide: true
  }
} satisfies ColDefMap<TradeRow>;

const tradesMonitorColumnLibrary: TableServerColumnLibrary<TradeRow> = {
  defaultColumn: tradesMonitorDefaultColumn,
  columns: tradesMonitorColumns
};

export const buildTradesMonitorColumnDefs = () =>
  buildColumnDefs(tradesMonitorColumnLibrary, 'tradesMonitor');
