import semver, { SemVer } from 'semver';
import type { Optional } from '@oms/shared/util-types';
import { AppEnv } from './app-env.class';

declare const __PROCESS_ENV__: string | Record<string, string> | null | undefined;

let parsedCache: Record<string, string> | NodeJS.ProcessEnv | undefined;

export const getEnvVar = (key: string, defaultValue = ''): string => {
  let envs = parsedCache || __PROCESS_ENV__ || process.env;

  if (typeof envs === 'string') {
    envs = JSON.parse(envs) as Record<string, string>;
    parsedCache = envs;
  }

  const envVar = envs?.[key];

  if (!envVar) {
    return defaultValue;
  }

  return `${envVar}`;
};

// App Env ---------------------------------------------------------------- /

export function getAppEnv(): Optional<AppEnv>;
export function getAppEnv(or: AppEnv): AppEnv;
// Impl
export function getAppEnv(fallback?: AppEnv): Optional<AppEnv> {
  const envString = getEnvVar('NX_ENVIRONMENT', getEnvVar('NODE_ENV'));
  if (!envString) return fallback;
  return AppEnv.fromString(envString);
}

// Version string ---------------------------------------------------------------- /

export interface VersionFormatOptions {
  prependV?: boolean;
}

export const formatVersion = (
  version?: string | SemVer,
  options?: VersionFormatOptions
): Optional<string> => {
  if (!version) return undefined;
  const prefix = () => (options?.prependV ? 'v' : '');
  if (version instanceof SemVer) {
    return prefix() + version.format();
  }
  if (!semver.valid(version)) {
    return undefined;
  }
  return prefix() + version;
};
