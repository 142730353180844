import ViewTabsList from '@app/widgets/common/components/view.tabs-list';
import { useClosestAppFlexLayoutActorProps } from '@app/common/workspace/workspace.hooks';
import type { TradeViewLayoutComponentProps } from './trade-view.layout.config';
import TradeViewTradingOrderGrid from './trade-view.trading-order.grid';
import { UpdatedTrade, withUpdatedTrade } from '@app/data-access/hoc/with-updated-trade';

const getTabsListProps = (tradingOrderId: string) => [
  {
    name: 'Trading order',
    value: 'tradingOrder',
    tabContent: <TradeViewTradingOrderGrid id={tradingOrderId} />
  },
  { name: 'Market charges', value: 'marketCharges', tabContent: <div>Market charges...</div> }
];

export const TradeViewTabbedDetailsWidget = ({ data: trade }: UpdatedTrade) => {
  const [props] = useClosestAppFlexLayoutActorProps<TradeViewLayoutComponentProps>();

  if (!props) {
    throw new Error('TradeViewTabbedDetailsWidget requires a trading order ID');
  }
  const { tradingOrderId } = trade;
  return <ViewTabsList defaultValue="tradingOrder" tabs={getTabsListProps(tradingOrderId)} />;
};

export default withUpdatedTrade(TradeViewTabbedDetailsWidget);
