import { openBulkRepairTrade, openTradeModify } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { RepairCategory } from '@oms/generated/frontend';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { ValstroEntitlements } from '@oms/generated/frontend';
import { Maybe } from '@oms/shared/util-types';
import { t } from '@oms/codegen/translations';

type RepairTradeActionArgs = {
  id?: Maybe<string>;
  category?: RepairCategory;
};

function getRepairableRows(data: RepairTradeActionArgs[]) {
  return data.filter(
    (selectedRow) =>
      selectedRow.category === RepairCategory.TradeCreate ||
      selectedRow.category === RepairCategory.TradeModify
  );
}

function isVisible(data: RepairTradeActionArgs[]) {
  return getRepairableRows(data).length > 0;
}

export const repairCreateTradeActionOnChange = async <T extends RepairTradeActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data, workspace } = ctx;

  // Repairs can only apply to RepairCategory.TradeCreate and RepairCategory.TradeModify
  const repairableRows = getRepairableRows(data);

  // Collect the row's ids.
  const tradeRepairRequestIds = repairableRows.map((row) => row.id || '');

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    if (repairableRows.length === 0) {
      return;
    }

    if (repairableRows.length === 1) {
      // Only 1 row selected. "Regular" repair.
      const id = tradeRepairRequestIds[0];
      openTradeModify(workspace.getLeaderProcessId(), {
        windowId: `trade-repair-${id}`,
        form: {
          formId: `trade-repair-form-${id}`,
          input: {
            id,
            entryType: 'trade-repair'
          },
          triggerValidationOnOpen: true
        },
        title: 'Repair Trade'
      }).catch((e) => {
        openMessageDialog(`Error: ${e}`, workspace).catch(console.error);
        console.error(e);
      });
    } else {
      // Multiple rows selected. "Bulk" repair.
      openBulkRepairTrade(workspace.getLeaderProcessId(), {
        windowId: `trade-repair`,
        form: {
          formId: `trade-repair-form`,
          input: {
            tradeRepairRequestIds: tradeRepairRequestIds
          }
        },
        title: 'Repair Trades'
      }).catch((e) => {
        openMessageDialog(`Error: ${e}`, workspace).catch(console.error);
        console.error(e);
      });
    }
  }
};

export const repairCreateTradeAction =
  <T extends RepairTradeActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('repair_trade')
      .toolbar((t) =>
        t.component('action-button').id('repair_trade_button').location('HorizontalToolbarRight').props({
          isDisabled: true,
          content: 'Repair'
        })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.RepairQueueManage]);
      })
      .customMenu((m) =>
        m
          .name('Repair')
          .visible(({ rowData }) => isVisible(rowData))
          .tabName(t('app.common.grids.contextMenuTabs.action'))
      )
      .onChange<ActionComponentConfig<T>>(repairCreateTradeActionOnChange);
