import { useClosestAppFlexLayoutActorProps } from '@app/common/workspace/workspace.hooks';
import type { TradeViewLayoutComponentProps } from './trade-view.layout.config';
import ViewTabsList from '@app/widgets/common/components/view.tabs-list';

const getTabsListProps = () => [
  { name: 'Executions', value: 'executions', tabContent: <div>Executions...</div> },
  { name: 'Audit trail', value: 'auditTrail', tabContent: <div>Audit trail...</div> }
];

export const TradeViewTabbedGridsWidget = () => {
  const [props] = useClosestAppFlexLayoutActorProps<TradeViewLayoutComponentProps>();

  if (!props) {
    throw new Error('TradeViewTabbedGridsWidget requires a trading order ID');
  }

  return <ViewTabsList defaultValue="executions" tabs={getTabsListProps()}></ViewTabsList>;
};

export default TradeViewTabbedGridsWidget;
