export const SNAPSHOT_APP_SUB_SCHEMA = {
  TIME_AND_SALES: 'TIME_AND_SALES',
  CURRENCIES_GRID: 'CURRENCIES_GRID',
  EXCHANGES_GRID: 'EXCHANGES_GRID',
  INSTRUMENT_RESTRICTIONS_GRID: 'INSTRUMENT_RESTRICTIONS_GRID',
  INSTRUMENTS: 'INSTRUMENTS',
  ABOUT_VALSTRO: 'ABOUT_VALSTRO',
  SYSTEM_LAUNCHER: 'SYSTEM_LAUNCHER',
  SYSTEM_PLACEHOLDER: 'SYSTEM_PLACEHOLDER',
  SYSTEM_REMOTE_FORM: 'SYSTEM_REMOTE_FORM',
  SYSTEM_IMPORT_SNAPSHOT: 'SYSTEM_IMPORT_SNAPSHOT',
  SYSTEM_LAYOUTS: 'SYSTEM_LAYOUTS',
  SYSTEM_SNAPSHOTS: 'SYSTEM_SNAPSHOTS',
  EVENT_DETAILS: 'EVENT_DETAILS',
  INVESTOR_ORDER_MONITOR_GRID: 'INVESTOR_ORDER_MONITOR_GRID',
  IO_VIEW_ORDER_INFORMATION: 'IO_VIEW_ORDER_INFORMATION',
  IO_VIEW_SIDE_PANEL: 'IO_VIEW_SIDE_PANEL',
  IO_VIEW_TABBED_GRIDS: 'IO_VIEW_TABBED_GRIDS',
  IO_VIEW_TABBED_SUMMARIES: 'IO_VIEW_TABBED_SUMMARIES',
  MAPPABLE_ORDERS: 'MAPPABLE_ORDERS',
  MONTAGE_DATA_PANEL: 'MONTAGE_DATA_PANEL',
  MONTAGE_SIDE_PANEL: 'MONTAGE_SIDE_PANEL',
  NEW_ORDERS_GRID: 'NEW_ORDERS_GRID',
  NEW_ORDERS_SIDE_PANEL: 'NEW_ORDERS_SIDE_PANEL',
  NOTIFICATIONS: 'NOTIFICATIONS',
  ORDER_VWAP: 'ORDER_VWAP',
  PENDING_MODIFICATIONS_GRID: 'PENDING_MODIFICATIONS_GRID',
  PENDING_MODIFICATIONS_SIDE_PANEL: 'PENDING_MODIFICATIONS_SIDE_PANEL',
  REPAIR_QUEUE_INVESTOR_ORDERS_GRID: 'REPAIR_QUEUE_INVESTOR_ORDERS_GRID',
  REPAIR_QUEUE_TRADES_GRID: 'REPAIR_QUEUE_TRADES_GRID',
  TRADES_MONITOR_GRID: 'TRADES_MONITOR_GRID',
  TRADING_ORDER_MONITOR_GRID: 'TRADING_ORDER_MONITOR_GRID',
  TO_VIEW_ORDER_INFORMATION: 'TO_VIEW_ORDER_INFORMATION',
  TO_VIEW_SIDE_PANEL: 'TO_VIEW_SIDE_PANEL',
  TO_VIEW_TABBED_GRIDS: 'TO_VIEW_TABBED_GRIDS',
  TO_VIEW_TABBED_SUMMARIES: 'TO_VIEW_TABBED_SUMMARIES',
  USER_PREFERENCES: 'USER_PREFERENCES',
  MONTAGE_TOOLBAR: 'MONTAGE_TOOLBAR',
  STRATEGY_PARAMETERS: 'STRATEGY_PARAMETERS',
  TRADE_VIEW_SIDE_PANEL: 'TRADE_VIEW_SIDE_PANEL',
  TRADE_VIEW_TABBED_GRIDS: 'TRADE_VIEW_TABBED_GRIDS',
  TRADE_VIEW_INFORMATION: 'TRADE_VIEW_INFORMATION',
  TRADE_VIEW_TABBED_DETAILS: 'TRADE_VIEW_TABBED_DETAILS',
  POSITIONS_ACCOUNT_GRID: 'POSITIONS_ACCOUNT_GRID',
  POSITIONS_INSTRUMENT_GRID: 'POSITIONS_INSTRUMENT_GRID',
  NOTIFICATIONS_SETTINGS: 'NOTIFICATIONS_SETTINGS',
  INVESTOR_ORDER_MONITOR: 'INVESTOR_ORDER_MONITOR',
  INVESTOR_ORDER_VIEW: 'INVESTOR_ORDER_VIEW',
  MONTAGE: 'MONTAGE',
  NEW_ORDERS: 'NEW_ORDERS',
  PENDING_MODIFICATIONS: 'PENDING_MODIFICATIONS',
  REPAIR_QUEUE: 'REPAIR_QUEUE',
  TRADES_MONITOR: 'TRADES_MONITOR',
  TRADING_ORDER_MONITOR: 'TRADING_ORDER_MONITOR',
  TRADING_ORDER_VIEW: 'TRADING_ORDER_VIEW',
  POSITIONS: 'POSITIONS',
  TRADE_VIEW: 'TRADE_VIEW',
  ACTION_BUTTON_FORM_INPUT: 'ACTION_BUTTON_FORM_INPUT',
  ACTION_BUTTON_FORM_VALUES: 'ACTION_BUTTON_FORM_VALUES',
  ACTION_BUTTON_LAYOUT_FORM_INPUT: 'ACTION_BUTTON_LAYOUT_FORM_INPUT',
  ACTION_BUTTON_LAYOUT_FORM_VALUES: 'ACTION_BUTTON_LAYOUT_FORM_VALUES',
  CURRENCY_FORM_FORM_INPUT: 'CURRENCY_FORM_FORM_INPUT',
  CURRENCY_FORM_FORM_VALUES: 'CURRENCY_FORM_FORM_VALUES',
  EXCHANGE_FORM_FORM_INPUT: 'EXCHANGE_FORM_FORM_INPUT',
  EXCHANGE_FORM_FORM_VALUES: 'EXCHANGE_FORM_FORM_VALUES',
  INSTRUMENT_RESTRICTION_ENTRY_FORM_INPUT: 'INSTRUMENT_RESTRICTION_ENTRY_FORM_INPUT',
  INSTRUMENT_RESTRICTION_ENTRY_FORM_VALUES: 'INSTRUMENT_RESTRICTION_ENTRY_FORM_VALUES',
  SAVE_SNAPSHOT_FORM_FORM_INPUT: 'SAVE_SNAPSHOT_FORM_FORM_INPUT',
  SAVE_SNAPSHOT_FORM_FORM_VALUES: 'SAVE_SNAPSHOT_FORM_FORM_VALUES',
  CROSS_PRINCIPAL_FILL_FORM_INPUT: 'CROSS_PRINCIPAL_FILL_FORM_INPUT',
  CROSS_PRINCIPAL_FILL_FORM_VALUES: 'CROSS_PRINCIPAL_FILL_FORM_VALUES',
  INVESTOR_ORDER_ENTRY_FORM_INPUT: 'INVESTOR_ORDER_ENTRY_FORM_INPUT',
  INVESTOR_ORDER_ENTRY_FORM_VALUES: 'INVESTOR_ORDER_ENTRY_FORM_VALUES',
  PRINCIPLE_FILL_FORM_FORM_INPUT: 'PRINCIPLE_FILL_FORM_FORM_INPUT',
  PRINCIPLE_FILL_FORM_FORM_VALUES: 'PRINCIPLE_FILL_FORM_FORM_VALUES',
  ROUTE_ORDER_FORM_INPUT: 'ROUTE_ORDER_FORM_INPUT',
  ROUTE_ORDER_FORM_VALUES: 'ROUTE_ORDER_FORM_VALUES',
  TRADE_MODIFY_FORM_INPUT: 'TRADE_MODIFY_FORM_INPUT',
  TRADE_MODIFY_FORM_VALUES: 'TRADE_MODIFY_FORM_VALUES',
  REJECT_INVESTOR_ORDER_FORM_INPUT: 'REJECT_INVESTOR_ORDER_FORM_INPUT',
  REJECT_INVESTOR_ORDER_FORM_VALUES: 'REJECT_INVESTOR_ORDER_FORM_VALUES',
  APPLY_SETTLEMENT_FX_RATE_FORM_INPUT: 'APPLY_SETTLEMENT_FX_RATE_FORM_INPUT',
  APPLY_SETTLEMENT_FX_RATE_FORM_VALUES: 'APPLY_SETTLEMENT_FX_RATE_FORM_VALUES',
  CANCEL_EXECUTIONS_FORM_INPUT: 'CANCEL_EXECUTIONS_FORM_INPUT',
  CANCEL_EXECUTIONS_FORM_VALUES: 'CANCEL_EXECUTIONS_FORM_VALUES',
  ORDER_VWAP_FORM_FORM_INPUT: 'ORDER_VWAP_FORM_FORM_INPUT',
  ORDER_VWAP_FORM_FORM_VALUES: 'ORDER_VWAP_FORM_FORM_VALUES',
  REJECT_PENDING_MODIFICATION_FORM_INPUT: 'REJECT_PENDING_MODIFICATION_FORM_INPUT',
  REJECT_PENDING_MODIFICATION_FORM_VALUES: 'REJECT_PENDING_MODIFICATION_FORM_VALUES',
  BULK_REPAIR_TRADE_FORM_INPUT: 'BULK_REPAIR_TRADE_FORM_INPUT',
  BULK_REPAIR_TRADE_FORM_VALUES: 'BULK_REPAIR_TRADE_FORM_VALUES',
  ENABLE_QUOTE_FORM_INPUT: 'ENABLE_QUOTE_FORM_INPUT',
  ENABLE_QUOTE_FORM_VALUES: 'ENABLE_QUOTE_FORM_VALUES',
  MONTAGE_FILTERS_FORM_FORM_INPUT: 'MONTAGE_FILTERS_FORM_FORM_INPUT',
  MONTAGE_FILTERS_FORM_FORM_VALUES: 'MONTAGE_FILTERS_FORM_FORM_VALUES',
  MOCK_USER_SETTINGS_FORM_INPUT: 'MOCK_USER_SETTINGS_FORM_INPUT',
  MOCK_USER_SETTINGS_FORM_VALUES: 'MOCK_USER_SETTINGS_FORM_VALUES',
  MONTAGE_SETTINGS_FORM_INPUT: 'MONTAGE_SETTINGS_FORM_INPUT',
  MONTAGE_SETTINGS_FORM_VALUES: 'MONTAGE_SETTINGS_FORM_VALUES',
  ORDER_SETTINGS_FORM_INPUT: 'ORDER_SETTINGS_FORM_INPUT',
  ORDER_SETTINGS_FORM_VALUES: 'ORDER_SETTINGS_FORM_VALUES',
  MONTAGE_BUMP_PRICE_FORM_INPUT: 'MONTAGE_BUMP_PRICE_FORM_INPUT',
  MONTAGE_BUMP_PRICE_FORM_VALUES: 'MONTAGE_BUMP_PRICE_FORM_VALUES'
} as const;
