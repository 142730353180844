import { APP_DISPLAY_FIELD_COMPONENT_TYPE, AppDisplayGrid } from '@oms/frontend-foundation';
import type { AppDisplayGridProps } from '@oms/frontend-foundation';
import { useMemo } from 'react';
import { Box, Text } from '@oms/shared-frontend/ui-design-system';
import type { Sprinkles } from '@oms/shared-frontend/ui-design-system';
import { withUpdatedInvestorOrder } from '@app/data-access/hoc/with-updated-investor-order';
import type { UpdatedInvestorOrder } from '@app/data-access/hoc/with-updated-investor-order';
import { paneStyle } from '@app/widgets/common/styles/trading.styles';
import { InvestorOrderViewUtilityButtonGroup } from './investor-order-view.utility-button-group';
import type { InvestorOrderRow } from '@oms/generated/frontend';

const getDisplayGridItems = (values: InvestorOrderRow): AppDisplayGridProps['items'] => {
  return [
    {
      label: 'Side',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Badge,
        value: values.side
      }
    },
    {
      label: 'Instrument',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: values.instrumentDisplayCode
      }
    },
    {
      label: 'Account',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: values.investorAccountName
      }
    },
    {
      label: 'Quantity',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: values.totalQuantity,
        format: 'quantity'
      }
    },
    {
      label: 'Price',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Badge,
        value: values.orderType
      }
    }
  ];
};

const gridProps: AppDisplayGridProps['gridProps'] = {
  columns: 3,
  rows: 2,
  columnGap: 10,
  rowGap: 3
};

const sx: Sprinkles = { ...paneStyle, padding: 5 };

export const InvestorOrderViewOrderInformationWidget = ({ data }: UpdatedInvestorOrder) => {
  const displayGridItems = useMemo(() => (data ? getDisplayGridItems(data) : []), [data]);

  return (
    <Box sx={{ ...sx, padding: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 5 }}>
        <Text sx={{ color: 'white', marginBottom: 3, display: 'block' }}>Order Information</Text>
        <InvestorOrderViewUtilityButtonGroup
          orderStatus={data.status}
          orderId={data.id}
          linkedTradingOrderId={data.parentTradingOrderId}
        />
      </Box>
      <AppDisplayGrid items={displayGridItems} labelSize="small" gridProps={gridProps} />
    </Box>
  );
};

export default withUpdatedInvestorOrder(InvestorOrderViewOrderInformationWidget, { sx });
