import type Keycloak from 'keycloak-js';
import type { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { ValstroEntitlements, fromPascalToIndustrySpec } from '@oms/codegen/entitlements';
import { getStringFromDynamicObject } from '@oms/shared/util';
import type { ParsedIdentityToken, ValstroEntitlement, ExtendedTokenProperties } from './keycloak.types';
import { DEFAULT_KC_TOKEN_PARSED } from './auth.contracts';
import getLabelForUser from './get-label-for-user.util';
import { omit } from 'lodash';

/**
 * Initialize a ParsedIdentityToken from an existing KeycloakTokenParsed
 * or ParsedIdentityToken instance
 *
 * @param kcToken Optional Keycloak token
 * @param newToken Optional ParsedIdentityToken instance
 * @returns ParsedIdentityToken instance
 */
export const mergeParsedKeycloakToken = (
  kcToken?: KeycloakTokenParsed,
  newToken?: ParsedIdentityToken
): ParsedIdentityToken => {
  if (kcToken && newToken) {
    // spread newToken onto the existing KeycloakToken
    return { ...kcToken, ...newToken };
  } else if (kcToken && !newToken) {
    // spread KeycloakToken onto the default ParsedIdentityToken
    return { ...DEFAULT_KC_TOKEN_PARSED, ...kcToken };
  } else {
    // no Keycloak token provided- return default ParsedIdentityToken
    return DEFAULT_KC_TOKEN_PARSED;
  }
};

/**
 * Adds in profile information if available.
 *
 * @param tokenParsed - Optional Keycloak token
 * @param profile - Optional Keycloak profile
 * @returns `ParsedIdentityToken` instance with profile info merged and/or defaults
 */
export const enhanceParsedTokenWithProfile = (
  tokenParsed?: KeycloakTokenParsed,
  profile?: KeycloakProfile
): ParsedIdentityToken => {
  const extendedKeys: (keyof ExtendedTokenProperties)[] = [
    'id',
    'firstName',
    'lastName',
    'name',
    'username',
    'email',
    'group'
  ];
  const base: KeycloakTokenParsed = omit(tokenParsed ?? {}, ...extendedKeys);
  const id = profile?.id || tokenParsed?.sub || DEFAULT_KC_TOKEN_PARSED.id;
  const firstName = profile?.firstName || getStringFromDynamicObject(base, 'given_name');
  const lastName = profile?.lastName || getStringFromDynamicObject(base, 'family_name');
  const preferredUsername = getStringFromDynamicObject(base, 'preferred_username');
  const username = profile?.username || preferredUsername?.split('@')[0];
  const email = (() => {
    if (profile?.email) return profile?.email;
    return preferredUsername?.includes('@') ? preferredUsername : DEFAULT_KC_TOKEN_PARSED.email;
  })();
  const name =
    getLabelForUser({
      id,
      firstName,
      lastName,
      username,
      email
    }) || DEFAULT_KC_TOKEN_PARSED.name;
  const group = DEFAULT_KC_TOKEN_PARSED.group;
  const extended: ExtendedTokenProperties = {
    id,
    firstName,
    lastName,
    name,
    username,
    email,
    group
  };
  return {
    ...omit(base, 'given_name', 'family_name', 'preferred_username'),
    ...extended
  };
};

export const parseKeycloakToken = (keycloak: Keycloak) => {
  const { tokenParsed, profile } = keycloak;
  if (!(tokenParsed && Object.keys(tokenParsed))) {
    return DEFAULT_KC_TOKEN_PARSED;
  }
  const enhancedToken = enhanceParsedTokenWithProfile(tokenParsed, profile);
  return mergeParsedKeycloakToken(tokenParsed, enhancedToken);
};

const ValstroEntitlementStrings: Record<string, ValstroEntitlement> = Object.keys(ValstroEntitlements).reduce(
  (acc, entitlement) => ({ ...acc, [fromPascalToIndustrySpec(entitlement)]: entitlement }),
  {}
);

const convertIndustrySpecToEntitlement = (industrySpec: string): ValstroEntitlement | undefined =>
  ValstroEntitlementStrings[industrySpec];

export function getUserRoles(parsedToken: ParsedIdentityToken): ValstroEntitlement[] {
  const roles = parsedToken?.realm_access?.roles;

  if (!roles) {
    return [];
  }
  return roles.reduce<ValstroEntitlement[]>((acc, role) => {
    const entitlement = role === 'Super User' ? 'Super User' : convertIndustrySpecToEntitlement(role);
    if (!entitlement) {
      console.error(
        `Keycloak returned an unrecognized user role: "${role}". Omitting it from the user's roles.`
      );
    }
    return entitlement ? acc.concat(entitlement) : acc;
  }, []);
}

export function hasRequiredEntitlements(
  userRoles: ValstroEntitlement[],
  entitlements: ValstroEntitlement[]
): boolean {
  if (!entitlements || !entitlements.length) {
    return true;
  }

  return entitlements.some((entitlement) => userRoles.includes(entitlement));
}
