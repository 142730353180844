import type {
  CoverageModel,
  CoverageModelInput,
  CoveragePerson,
  CoveragePersonInput,
  Maybe
} from '@oms/generated/frontend';
import { CoverageCategory } from '@oms/generated/frontend';
import { compactMap, createLogger } from '@oms/shared/util';
import type { Optional } from '@oms/shared/util-types';
import { v4 as UUID } from 'uuid';
import { ExtendedCoverageModel } from './extended-coverage-model.class';
import type { CoverageDisplayInfo, CoveragePersonDisplayable } from './coverage.model';
import type { AnyInvestorAccount, SimpleInvestorAccount } from '@app/common/types/accounts/types';
import { isSimpleInvestorAccount, simplifyAccountOrThrow } from '@app/common/types/accounts/utils';
import { getLabelForUser } from '@app/common/auth/get-label-for-user.util';
import type { UserDisplayOptions } from '@app/common/auth/get-label-for-user.util';

export const extendCoverageModels = (
  coverageModels: Maybe<CoverageModel>[],
  investorAccount?: AnyInvestorAccount | SimpleInvestorAccount
): ExtendedCoverageModel[] =>
  compactMap(coverageModels, (coverageModel) => {
    if (!coverageModel) return;
    return new ExtendedCoverageModel(coverageModel, investorAccount);
  });

export const collectCoverageModelsFromAccounts = (
  investorAccounts: Maybe<AnyInvestorAccount>[]
): ExtendedCoverageModel[] => {
  const allCoverageModels: ExtendedCoverageModel[] = [];
  investorAccounts.forEach((investorAccount) => {
    if (!investorAccount) return;
    const { coverageModels } = investorAccount;
    if (!coverageModels) return;
    coverageModels.forEach((coverageModel) => {
      if (!coverageModel) return;
      allCoverageModels.push(new ExtendedCoverageModel(coverageModel, investorAccount));
    });
  });
  return allCoverageModels;
};

export type CoveragePersonDisplayOptions = Omit<UserDisplayOptions, 'preferenceOrder'> &
  Partial<{
    coverageModelId: string;
  }>;

const getLabelForCoveragePerson = (
  coveragePerson: Maybe<CoveragePersonDisplayable>,
  fallbackLabel: string
): string => coveragePerson?.label ?? fallbackLabel;

/**
 * @param coverageGroup - A list of displayable coverage people
 * @param [options] - Options object
 * @returns A string label for the group
 */
export const getLabelForCoverageGroup = (
  coverageGroup: CoveragePersonDisplayable[],
  options?: CoveragePersonDisplayOptions
): string => {
  const { fallbackLabel = '' } = options ?? {};
  if (coverageGroup.length === 0) return fallbackLabel;
  const [firstMember] = coverageGroup;
  const firstLabel = getLabelForCoveragePerson(firstMember, fallbackLabel);
  if (coverageGroup.length > 1) {
    return `${firstLabel.split(' ')[0]} and ${coverageGroup.length - 1} more`;
  } else {
    return firstLabel;
  }
};

/**
 * @param coverageGroup - A single displayable coverage person
 * @param [options] - Options object
 * @returns An object of information needed to display a coverage column in the UI
 */
const getCoverageDisplayInfoForSingleCoveragePerson = (
  coveragePerson: Maybe<CoveragePersonDisplayable>,
  options?: CoveragePersonDisplayOptions
): CoverageDisplayInfo => {
  const { fallbackLabel = '', coverageModelId } = options ?? {};
  const count = coveragePerson ? 1 : 0;
  const label = getLabelForCoveragePerson(coveragePerson, fallbackLabel);
  const { id, src } = coveragePerson ?? {};
  const coverageDisplayInfo: CoverageDisplayInfo = { label, count, id: id ?? coverageModelId ?? UUID() };
  if (src) coverageDisplayInfo.src = src;
  return coverageDisplayInfo;
};

/**
 * @param coveragePerson - A list of displayable coverage people
 * @param [options] - Options object
 * @returns An object of information needed to display a coverage column in the UI
 */
export const getCoverageDisplayInfo = (
  coverageGroup: CoveragePersonDisplayable[],
  options?: CoveragePersonDisplayOptions
): CoverageDisplayInfo => {
  if (coverageGroup.length > 1) {
    const { coverageModelId } = options ?? {};
    const label = getLabelForCoverageGroup(coverageGroup, options);
    const count = coverageGroup.length;
    const children = coverageGroup.map((coveragePerson) =>
      getCoverageDisplayInfoForSingleCoveragePerson(coveragePerson, options)
    );
    const coverageDisplayInfo: CoverageDisplayInfo = {
      label,
      children,
      count,
      id: coverageModelId ?? UUID()
    };
    const [first] = children;
    if (first?.src) coverageDisplayInfo.src = first.src;
    return coverageDisplayInfo;
  } else {
    const [coveragePerson] = coverageGroup;
    return getCoverageDisplayInfoForSingleCoveragePerson(coveragePerson, options);
  }
};

/**
 * @param coveragePerson - An object of information about coverage person
 * @param [options] - Options object
 * @returns The coverage person object extended with enhanced UI capabilities and info
 */
export const makeCoveragePersonDisplayable = (
  coveragePerson: CoveragePerson,
  options?: UserDisplayOptions
): CoveragePersonDisplayable => {
  const { user } = coveragePerson;
  const label = getLabelForUser(user, options);
  const coveragePersonDisplayable: CoveragePersonDisplayable = {
    ...coveragePerson,
    label
  };
  const { id: userId, username, firstName, lastName, email } = user ?? {};
  if (userId) coveragePersonDisplayable.userId = userId;
  if (username) coveragePersonDisplayable.username = username;
  if (firstName) coveragePersonDisplayable.firstName = firstName;
  if (lastName) coveragePersonDisplayable.lastName = lastName;
  if (email) coveragePersonDisplayable.email = email;
  return coveragePersonDisplayable;
};

export const simplifyInvestorAccountOrThrow = (
  investorAccount: AnyInvestorAccount | SimpleInvestorAccount
): SimpleInvestorAccount => {
  const simplified = simplifyAccountOrThrow(investorAccount);
  if (!isSimpleInvestorAccount(simplified))
    throw new TypeError(
      `Expected simplified account to be an investor account but the type is "${simplified.type}".`
    );
  return simplified;
};

export const simplifyInvestorAccount = (
  investorAccount: AnyInvestorAccount | SimpleInvestorAccount
): Optional<SimpleInvestorAccount> => {
  try {
    return simplifyInvestorAccountOrThrow(investorAccount);
  } catch (e) {
    const l = createLogger({ name: 'simplifyInvestorAccount' });
    l.error(e);
    return undefined;
  }
};

/**
 * Converts an enhanced coverage person to the input type
 * @param coverageModel - An enhanced coverage person
 * @returns Coverage person input type
 */
export const convertPersonForInput = (
  coveragePerson: CoveragePersonDisplayable,
  category?: CoverageCategory
): CoveragePersonInput => {
  const { id, username: userId, coverageCategory } = coveragePerson;
  return {
    coverageCategory: coverageCategory ?? category!,
    id,
    userId
  };
};

/**
 * Converts an enhanced coverage model to the input type
 * @param coverageModel - An enhanced coverage model
 * @returns Coverage model input type
 */
export const convertCoverageModelForInput = (coverageModel: ExtendedCoverageModel): CoverageModelInput => {
  const coveragePersons: CoveragePersonInput[] = [];
  const {
    accountId: id,
    sendingDesk,
    type,
    primaryCoverage,
    backupCoverage,
    supportingCoverage
  } = coverageModel;
  primaryCoverage.forEach((person) => convertPersonForInput(person, CoverageCategory.AccountPrimary));
  backupCoverage.forEach((person) => convertPersonForInput(person, CoverageCategory.AccountBackup));
  supportingCoverage.forEach((person) => convertPersonForInput(person, CoverageCategory.AccountSupport));

  return {
    id,
    sendingDesk,
    type,
    coveragePersons,
    isDefault: false
  };
};
