import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { type ComboBoxItem } from '../../combo-box/combo-box.types';
import { ComboBox } from '../../combo-box/combo-box.component';
import * as MenuPrimitive from '@radix-ui/react-menu';
import { Button, HStack } from '@oms/shared-frontend/ui-design-system';
import { useObservableState } from 'observable-hooks';
import type { IContextMenu, MousePos } from './in-window.context-menu.model';
import { ContextMenuService } from './in-window.context-menu.service';
import { isPromiseLike } from '@oms/shared/util';

// ------------------------ CONTEXT ------------------------

export const InWindowContextMenuContext = createContext<IContextMenu>({} as IContextMenu);

export const useInWindowContextMenuApi = () => {
  return useContext(InWindowContextMenuContext);
};

// ------------------------ PROVIDER ------------------------

const POPOVER_WIDTH = 280;
const POPOVER_MIN_LIST_HEIGHT = 50;
const POPOVER_MAX_LIST_HEIGHT = 148;
const POPOVER_ITEM_HEIGHT = 32;
const PRIMARY_BUTTONS_HEIGHT = 36;
const TABS_HEIGHT = 36;
const INPUT_HEIGHT = 44;
const MARGIN = 6;

const popoverStyle = { width: POPOVER_WIDTH } as React.CSSProperties;
const cmdStyles = {
  height: '100%'
} as React.CSSProperties;

export function InWindowContextMenuProvider({
  children,
  contextMenu
}: {
  children: React.ReactNode;
  contextMenu?: IContextMenu;
}) {
  const state = useMemo(() => contextMenu || new ContextMenuService(), []);
  const { primary, comboxItems, isOpen, mousePos } = useObservableState(state.$, {
    primary: [],
    secondary: [],
    comboxItems: [],
    isOpen: false,
    mousePos: { x: 0, y: 0 }
  });
  const hasPrimary = primary.length > 0;
  const hasTabs = useMemo(() => comboxItems.some((i) => i.type === 'tab'), [comboxItems]);

  const largestTabItemsLength = useMemo(() => {
    const rootItems = comboxItems.filter((i) => i.type === 'item');
    const tabs = comboxItems.filter((i) => i.type === 'tab');
    if (tabs.length === 0) {
      return rootItems.length;
    }
    let largest = 0;
    for (const tab of tabs) {
      const itemsInTabLength = tab.items.length;
      if (itemsInTabLength > largest) {
        largest = itemsInTabLength;
      }
    }
    return largest;
  }, [comboxItems]);

  const listHeight = largestTabItemsLength * POPOVER_ITEM_HEIGHT;
  const popoverListHeight =
    Math.min(Math.max(listHeight, POPOVER_MIN_LIST_HEIGHT), POPOVER_MAX_LIST_HEIGHT) + MARGIN;

  let popoverHeight = hasPrimary
    ? popoverListHeight + PRIMARY_BUTTONS_HEIGHT + INPUT_HEIGHT
    : popoverListHeight + INPUT_HEIGHT;

  if (hasTabs) {
    popoverHeight += TABS_HEIGHT;
  }

  const [inputValue, setInputValue] = useState('');
  const [selectedOptions, setSelectedItems] = useState<ComboBoxItem[]>([]);
  const mouseRef = useRef<MousePos>({ x: 0, y: 0 });
  mouseRef.current = mousePos;
  const virtualRef = React.useRef({
    getBoundingClientRect: () => DOMRect.fromRect({ width: 0, height: 0, ...mouseRef.current })
  });

  const onOpen = useCallback((isOpen: boolean) => {
    state.setOpen(isOpen);
  }, []);

  const onSubmit = useCallback((selectedItems: ComboBoxItem[]) => {
    state.run(selectedItems.map((s) => s.id));
    state.setOpen(false);
    setSelectedItems([]);
  }, []);

  const computedPopoverStyle = useMemo(() => {
    return {
      ...popoverStyle,
      height: popoverHeight
    };
  }, [popoverHeight]);

  const computedCmdStyles = useMemo(() => {
    return {
      ...cmdStyles,
      '--cmdk-list-max-height': `${popoverListHeight}px`
    };
  }, [popoverListHeight]);

  return (
    <InWindowContextMenuContext.Provider value={state}>
      <MenuPrimitive.Root open={isOpen} onOpenChange={onOpen}>
        <MenuPrimitive.Anchor virtualRef={virtualRef} />
        {children}
        <MenuPrimitive.Portal>
          <MenuPrimitive.Content
            className="ContextMenuContent"
            side="bottom"
            align="start"
            sideOffset={2}
            alignOffset={-2}
            style={computedPopoverStyle}
          >
            <div cmdk-sm="" cmdk-wrapper="" style={computedCmdStyles}>
              {primary.length > 0 && (
                <HStack spacing={2} sx={{ p: 2 }}>
                  {primary.map((item) => (
                    <Button
                      aria-label={`Button ${item.label}`}
                      key={item.id}
                      onClick={() => {
                        const result = item.onClick();
                        if (isPromiseLike(result)) {
                          result.catch(console.error);
                        }
                        state.setOpen(false);
                      }}
                      sx={{ flexGrow: 1 }}
                    >
                      {item.label}
                    </Button>
                  ))}
                </HStack>
              )}
              <ComboBox
                items={comboxItems}
                inputValue={inputValue}
                setInputValue={setInputValue}
                selectedItems={selectedOptions}
                setSelectedItems={setSelectedItems}
                removeOnBackspace={false}
                hiddenInput={false}
                onSubmit={onSubmit}
                hiddenSelectedItems={true}
                shouldAutoFilter={true}
                customFilter={customStartsWithFilter}
                submitReady={true}
                submitInstantly={true}
                strategy={'single-select'}
                allSwitchable={true}
                tabThroughTabs={true}
              />
            </div>
          </MenuPrimitive.Content>
        </MenuPrimitive.Portal>
      </MenuPrimitive.Root>
    </InWindowContextMenuContext.Provider>
  );
}

// ------------------------ UTIL ------------------------

function customStartsWithFilter(value: string, search: string) {
  return value.toLowerCase().startsWith(search.toLowerCase()) ? 1 : 0;
}
