import { InvestorOrderAuditTrailGrid } from './investor-order-view.audit-trail.grid';
import { InvestorOrderExecutionsGrid } from './investor-order-view.executions.grid';
import { InvestorOrderTradingOrdersGrid } from './investor-order-view.trading-orders.grid';
import { useClosestAppFlexLayoutActorProps } from '@app/common/workspace/workspace.hooks';
import type { InvestorOrderViewLayoutComponentProps } from './investor-order-view.layout.config';
import ViewTabsList from '@app/widgets/common/components/view.tabs-list';

const getTabListProps = (investorOrderId: string) => [
  {
    name: 'Executions',
    value: 'tabExecutions',
    tabContent: <InvestorOrderExecutionsGrid id={investorOrderId} />
  },
  {
    name: 'Trading Orders',
    value: 'tabTradingOrders',
    tabContent: <InvestorOrderTradingOrdersGrid id={investorOrderId} />
  },
  {
    name: 'Audit Trail',
    value: 'tabAuditTrail',
    tabContent: <InvestorOrderAuditTrailGrid investorOrderId={investorOrderId} />
  }
];
export const InvestorOrderViewTabbedGridsWidget = () => {
  const [props] = useClosestAppFlexLayoutActorProps<InvestorOrderViewLayoutComponentProps>();

  if (!props) {
    throw new Error('InvestorOrderViewTabbedGridsWidget requires an investor order ID');
  }

  const { id: investorOrderId } = props;

  return <ViewTabsList defaultValue="tabExecutions" tabs={getTabListProps(investorOrderId)} />;
};

export default InvestorOrderViewTabbedGridsWidget;
