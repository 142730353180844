import { isEmpty } from 'lodash';
import type { AnyRecord } from '@oms/shared/util-types';
import type { ActionContext } from '@oms/frontend-vgrid';
import type { ActionConfig } from './common.types';

export function isConfiguredButton<TRowData extends AnyRecord>(
  ctx: ActionContext<TRowData, AnyRecord>
): boolean {
  return isEmpty(ctx.config || {}) === false;
}

export function isHotButton<TRowData extends AnyRecord>(
  ctx: ActionContext<TRowData, AnyRecord>
): ctx is ActionContext<TRowData, ActionConfig<AnyRecord>> {
  if (!isConfiguredButton(ctx)) return false;
  const { allowsConfirmation = false, confirmation = true } = (ctx.config || {}) as Partial<
    ActionConfig<AnyRecord>
  >;
  return allowsConfirmation === true && !confirmation;
}
