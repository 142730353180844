import { Howl } from 'howler';
import { SharedNotification } from '@oms/shared/oms-common';
import positive1 from '/sounds/positive-1.mp3';
import positive2 from '/sounds/positive-2.mp3';
import negative from '/sounds/negative.mp3';

const positive1Sound = new Howl({ src: [positive1], preload: true });
const positive2Sound = new Howl({ src: [positive2], preload: true });
const negativeSound = new Howl({ src: [negative], preload: true });

export const notificationSoundLibrary: ReadonlyMap<SharedNotification.DomainDefaultNotificationName, Howl> =
  new Map([
    [SharedNotification.DomainDefaultNotificationName.IO_MODIFY, positive1Sound],
    [SharedNotification.DomainDefaultNotificationName.IO_NEW, positive2Sound],
    [SharedNotification.DomainDefaultNotificationName.IO_FAILED, negativeSound],
    [SharedNotification.DomainDefaultNotificationName.IO_CANCEL, positive1Sound],
    [SharedNotification.DomainDefaultNotificationName.IO_MODIFY_FAILED, negativeSound],
    [SharedNotification.DomainDefaultNotificationName.TRADE_FAILED, negativeSound],
    [SharedNotification.DomainDefaultNotificationName.TRADE_MODIFY_FAILED, negativeSound],
    [SharedNotification.DomainDefaultNotificationName.TO_CREATE_REJECTED, negativeSound],
    [SharedNotification.DomainDefaultNotificationName.TO_CANCEL_REJECTED, negativeSound],
    [SharedNotification.DomainDefaultNotificationName.TO_MODIFY_REJECTED, negativeSound],
    [SharedNotification.DomainDefaultNotificationName.TO_UNSOLICITED_CANCEL, negativeSound]
    // ['Capital Threshold Warning', negativeSound],
    // ['Unmatched Trade Report', negativeSound],
    // ['Trade Report Failed (rejected)', negativeSound],
    // ['Trade Request (OTC)', positive2Sound]
    // Trading Along
  ]);
