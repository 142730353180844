import { AgGridEvent, GridApi } from '@ag-grid-community/core';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { EventHandler } from './event.handler';
import { CUSTOM_EVENT_KEY, EventSource, GridEventType } from './event.source';
import { auditTime, Observable, Subscription } from 'rxjs';
import { Datasource } from '../models/datasource.model';
import { VGridInstance } from '../models/v.instance.model';

@scoped(Lifecycle.ContainerScoped)
export class DatasourceEventHandler implements EventHandler<any> {
  private datasource: Datasource<any>;

  public name = 'datasource-event-handler';
  private gridApi: GridApi | undefined;
  private subscription?: Subscription;

  constructor(@inject(VGridInstance.Datasource) datasource: Datasource<any>) {
    this.datasource = datasource;
  }

  public addEvents(eventSource: EventSource<GridEventType, any>) {
    eventSource
      .add('onGridReady', (e) => {
        const loadDataOnEvent = e.api.getGridOption('context')?.loadDataOnEvent;

        switch (loadDataOnEvent) {
          case CUSTOM_EVENT_KEY.GRID_CONFIG_READY:
            e.api.addEventListener(CUSTOM_EVENT_KEY.GRID_CONFIG_READY, (e: AgGridEvent) => {
              this.loadDataOnEvent(e);
            });
            break;
          case 'onGridReady':
            this.loadDataOnEvent(e);
            break;
          default:
            this.loadDataOnEvent(e);
        }
      })
      .add('onGridPreDestroyed', (_e) => {
        this.subscription?.unsubscribe();
      });
  }

  private loadDataOnEvent(e: AgGridEvent) {
    this.gridApi = e.api;

    if (this.gridApi.isDestroyed()) return;

    if (this.datasource.rowId && this.datasource.data$ && this.datasource.data$ instanceof Observable) {
      this.subscription = this.datasource.data$.pipe(auditTime(10)).subscribe((trans) => {
        if (this.gridApi?.isDestroyed()) return;
        this.gridApi?.updateGridOptions({ rowData: trans });
      });
    }
  }

  public removeEvents() {
    if (this.gridApi && !this.gridApi.isDestroyed()) {
      this.gridApi.dispatchEvent({ type: 'destroy' });
    }
  }
}
