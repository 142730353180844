import { FormatType, FormatterFunctionParams } from '@oms/shared/util';
import { Valued } from '@oms/shared/util-types';
import { FC, Fragment } from 'react';
import { Text, TextProps } from '../../../../layout/text/text';
import { robotoMono } from '../../../../theme/foundations/typography';
import { DISPLAY_FIELD_COMPONENT_TYPE } from '../../display-field.contracts';
import { Format } from '../../../format/format';
import { TextEllipser } from '../../../text-ellipser/text-ellipser';
import { getTickDirection } from './numeric.utils';

type DisplayType = 'range' | 'fraction';

export type NumericValueProps = {
  value?: string | number;
  textProps?: TextProps;
};

export type NumericPrimitiveValue = string | number;

export type NumericProps = Valued<
  NumericPrimitiveValue | NumericPrimitiveValue[] | NumericValueProps | NumericValueProps[]
> & {
  type: typeof DISPLAY_FIELD_COMPONENT_TYPE.Numeric;
  direction?: 'up' | 'down' | 'neutral';
  displayTickDirection?: boolean;
  format?: FormatType | [FormatType, Omit<FormatterFunctionParams, 'value'>];
  display?: DisplayType;
  onSingleClick?: (value: string | number | (string | number)[]) => void;
  onDoubleClick?: (value: string | number | (string | number)[]) => void;
  textProps?: TextProps;
};

const separatorMapping: Record<DisplayType, JSX.Element> = {
  range: <span>&nbsp;-&nbsp;</span>,
  fraction: <span>/</span>
};

export const Numeric: FC<NumericProps> = ({
  value,
  direction = 'neutral',
  format,
  display,
  textProps,
  displayTickDirection
}) => {
  const values = Array.isArray(value) ? value : [value];
  const formatType = Array.isArray(format) ? format[0] : format;
  const formatArgs = Array.isArray(format) ? format[1] : undefined;

  const separator = display && separatorMapping[display];

  return (
    <TextEllipser data-testid="numeric-container">
      {values.map((value, index) => {
        if (!value) return null;
        return (
          <Fragment key={index}>
            <Text
              key={index}
              sx={{
                userSelect: 'none',
                ...(displayTickDirection ? { display: 'flex' } : null),
                ...(!separator ? { marginRight: 3 } : null),
                ...(direction === 'up' || direction === 'down'
                  ? { color: direction === 'up' ? 'price.positive' : 'price.negative' }
                  : null)
              }}
              style={{ fontFamily: robotoMono }} // TODO: This should be a theme value?
              {...(typeof value === 'object' && 'textProps' in value ? value.textProps : textProps)}
              data-raw-value={typeof value === 'object' ? value.value : value}
            >
              <>
                {formatType ? (
                  <Format
                    type={formatType}
                    args={formatArgs}
                    value={typeof value === 'object' ? value.value || '' : value}
                  />
                ) : (
                  value
                )}
              </>
              <>{displayTickDirection && getTickDirection(direction)}</>
            </Text>
            {separator && index < values.length - 1 && separator}
          </Fragment>
        );
      })}
    </TextEllipser>
  );
};
