import React, { useCallback, useRef, useState } from 'react';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import type { FieldProps, IAdvancedSelectField, AnyRecord } from '@oms/frontend-foundation';
import { AdvancedSelectField, useFieldApi } from '@oms/frontend-foundation';
import { FORM_COMPONENT_TYPE } from '@app/forms/form-builder/common/form.contracts';
import { useOnInstrumentFollowEvent } from './instrument-follow.hooks';
import { InstrumentFollowToggleButton } from './instrument-follow.toggle-button';
import type { InstrumentFollowEvent } from './instrument-follow.contracts';
import type { InstrumentFollowRegistryKey } from './instrument-follow.toggle-state.registry';
import { INSTRUMENT_TRACKING_STATE_REGISTRY } from './instrument-follow.toggle-state.registry';

export type IInstrumentInnerValue = {
  sourceId?: string;
  sourceType?: string;
};

export type IInstrumentFollowField<TValidator = Validator> = Omit<
  IAdvancedSelectField<IInstrumentInnerValue, AnyRecord, TValidator>,
  'component'
> & {
  toggleStateSource?: InstrumentFollowRegistryKey;
  sourceTypes?: InstrumentFollowEvent['sourceType'][];
  component: typeof FORM_COMPONENT_TYPE.INSTRUMENT_FOLLOW;
};

export const InstrumentFollowField: React.FC<FieldProps<IInstrumentFollowField<any>>> = (props) => {
  const [isToggleEnabled, setIsToggleEnabled] = useState(false);
  const {
    toggleStateSource,
    sourceTypes = [],
    input: { onChange, value }
  } = useFieldApi<IInstrumentFollowField<any>>(props);
  const onChangeStableRef = useRef(onChange);
  const valueRef = useRef<InstrumentFollowEvent>(value);
  valueRef.current = value;
  onChangeStableRef.current = onChange;

  if (!toggleStateSource) {
    throw new Error('toggleStateSource is required for InstrumentFollowField');
  }

  const onInstrumentFollowEvent = useCallback(
    (event: InstrumentFollowEvent) => {
      if (!isToggleEnabled || valueRef.current?.sourceId === event.sourceId) {
        return;
      }

      onChangeStableRef.current({
        id: event.instrumentId,
        value: {
          sourceId: event.sourceId,
          sourceType: event.sourceType
        },
        label: event.instrumentDisplayCode
      });
    },
    [isToggleEnabled]
  );

  useOnInstrumentFollowEvent(onInstrumentFollowEvent, ...sourceTypes);

  const InstrumentStateWrapper =
    INSTRUMENT_TRACKING_STATE_REGISTRY[toggleStateSource as InstrumentFollowRegistryKey];

  if (!InstrumentStateWrapper) {
    throw new Error(`InstrumentStateWrapper not found for ${toggleStateSource}`);
  }

  return (
    <AdvancedSelectField
      {...props}
      component="advanced-select"
      // forceIsDisabled={isToggleEnabled} // TODO: Do we want to disable the field when the toggle is enabled?
    >
      <InstrumentStateWrapper onPressedChange={setIsToggleEnabled}>
        {(state) => (
          <InstrumentFollowToggleButton isPrimaryField={props.isPrimaryField} {...state}>
            ◎
          </InstrumentFollowToggleButton>
        )}
      </InstrumentStateWrapper>
    </AdvancedSelectField>
  );
};
